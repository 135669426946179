import { render, staticRenderFns } from "./mergeOrder.vue?vue&type=template&id=2808b227&scoped=true"
import script from "./mergeOrder.vue?vue&type=script&lang=js"
export * from "./mergeOrder.vue?vue&type=script&lang=js"
import style0 from "./mergeOrder.vue?vue&type=style&index=0&id=2808b227&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2808b227",
  null
  
)

export default component.exports