<template>
   <div>
      <van-sticky>
         <van-nav-bar :border="false" title="我要接龙" left-arrow @click-left="onClickLeft" fixed placeholder
            safe-area-inset-top />
      </van-sticky>
      <van-tabs v-model="active" ref="tabs">
         <van-tab v-for="item in solitaireList" :title="item.title" :key="item.id">
            <div class="mySolitaire" v-if="item.id == 1">
               <van-cell-group inset>
                  <van-cell :title-style="card" value-class="card" title="接龙店" :value="userinfo.storeName" />
                  <van-cell :title-style="card" :value-class="status ? 'greenC' : 'redC'" :value="status ? '开启' : '关闭'"
                     title="接龙状态" />
                  <van-cell :title-style="card" value-class="card" title="可接龙时间" value="09:00~21:00" />
                  <van-radio-group v-model="radio">
                     <van-cell :title-style="card" title="请选择价格范围" />
                     <van-cell :title-style="card" :title="priceArea[0]" clickable @click="radio = 0">
                        <template #right-icon>
                           <van-radio :name="0" />
                        </template>
                     </van-cell>
                     <van-cell :title-style="card" :title="priceArea[1]" clickable @click="radio = 1">
                        <template #right-icon>
                           <van-radio :name="1" />
                        </template>
                     </van-cell>
                  </van-radio-group>
                  <van-button class="cButton" :disabled="status ? false : true" @click="solitaireComfirm">确认接龙</van-button>
               </van-cell-group>
            </div>
            <div class="allSolitaire" v-if="item.id == 2">
               <div class="personNum">今日接龙人数:{{ item.data.length }}</div>
               <van-empty description="暂无数据" v-if="item.data.length == 0" />
               <div class="solCard" v-for="c in item.data">
                  <div>预约日期：{{ c.createTime }}</div>
                  <div>预约区间：{{ priceArea[c.solitaireType] }}</div>
                  <div>姓名：{{ c.user.realName }}</div>
                  <div>邀请码：{{ c.user.inviteCode }}</div>
                  <div>推荐人名称：{{ userinfo.realName }}</div>
                  <div>推荐人手机号：{{ userinfo.username }}</div>
               </div>
            </div>
            <div class="noSolitaire" v-if="item.id == 3">
               <van-empty description="暂无数据" v-if="item.data.length == 0" />
               <div class="userCard" v-for="user in item.data">
                  <div class="realName">{{ user.realName }}</div>
                  <div class="username">手机号：{{ user.username }}</div>
                  <div>邀请码：{{ user.inviteCode }}
                     <van-icon style="margin-left: 10px;" :name="copyIcon" size="20px"
                        @click="copyInviteCode(user.inviteCode)" />
                  </div>
                  <div class="createTime">注册时间：{{ user.createTime }}</div>
               </div>
            </div>
         </van-tab>
      </van-tabs>
   </div>
</template>
<script>
import { createSolitaire, finishToday, getUserNext } from '@/api/solitaire';
import { isInTimeRange } from '@/utils/DateTime';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';

export default {
   name: 'userSolitaire',
   data() {
      return {
         copyIcon: require("@/assets/copy.png"),
         active: 0,
         solitaireList: [
            {
               id: 1,
               title: "我要接龙",
            }, {
               id: 2,
               title: '已接龙',
               data: []
            }, {
               id: 3,
               title: '未接龙',
               data: []
            }
         ],
         card: {
            textAlign: 'left'
         },
         radio: null,
         priceArea: ["2000~5000", "5000~9000"],
         status: isInTimeRange("09:00", "21:00")
      }
   },
   computed: {
      ...mapGetters(["userinfo"])
   },
   watch: {

   },
   mounted() {
      this.finishSolitaireToday()
      this.getUserNextList()
   },
   methods: {
      async copyInviteCode(code) {
         try {
            await navigator.clipboard.writeText(code);
            Notify({ type: 'success', message: "复制成功" });
         } catch (err) {
            Notify({ type: 'danger', message: '复制失败' });
         }
      },
      onClickLeft() {
         this.$router.back()
      },
      async solitaireComfirm() {
         if (this.radio == null) {
            Notify({ type: 'danger', message: '请选择价格区间' })
            return;
         }
         let data = {
            storeCode: this.userinfo.storeCode,
            uid: this.userinfo.uid,
            solitaireType: this.radio
         }
         await createSolitaire(data).then(res => {
            Notify({ type: res.data.success ? 'success' : 'danger', message: res.data.msg })
            this.finishSolitaireToday()
         })
      },
      async finishSolitaireToday() {
         await finishToday({ uid: this.userinfo.uid }).then(res => {
            if (!res.data.success) {
               this.status = false
            }
         })
      },
      async getUserNextList() {
         await getUserNext({ inviteCode: this.userinfo.inviteCode }).then(res => {
            if (res.data.data != null) {
               this.solitaireList[1].data = res.data.data.solitaireVos
               this.solitaireList[2].data = res.data.data.users
            }
         })
      }
   }
};
</script>
<style lang='less' scoped>
.card {
   text-align: left;
}

.greenC {
   color: green;
   font-size: 16px;
   font-weight: 600;
   text-align: left;
}

.redC {
   color: red;
   font-weight: 600;
   font-size: 16px;
   text-align: left;
}

.mySolitaire {
   margin-top: 30px;
}

.cButton {
   background-color: rgb(165, 35, 136);
   color: #fff;
   width: 85%;
   margin: 20px 0;
}

.personNum {
   background-color: rgb(165, 35, 136);
   color: #fff;
   font-size: 13px;
   text-align: center;
   padding: 10px 0;
}

.solCard {
   background-color: #fff;
   width: 70%;
   padding: 20px 30px;
   border-radius: 10px;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   font-weight: 600;
   text-align: left;

   div {
      margin-top: 10px;
   }

   margin: 20px auto;
}

.userCard {
   margin: 20px;
   border-bottom: 1px solid rgb(138, 137, 137);
   text-align: left;

   div {
      padding-bottom: 15px;
   }
}

.realName,
.username {
   font-size: 18px;
   font-weight: 600;
   color: #000;
}
</style>