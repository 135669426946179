import { Post,Get } from "@/request/request";

export function createOrder(data){
   return Post("/orders/addOrders",data)
}

export function adminGetOrders(){
    return Get("/orders/adminGetOrders")
}

export function adminOrdersByStore(data){
    return Get('/orders/adminOrdersByStore',data)
}

export function getOrdersByStore(data){
    return Get("/orders/getOrdersByOrderStatus",data)
}

export function getOrdersByStatus(data){
    return Get("/orders/getOrdersByStatus",data)
}

export function designateOrders(data){
    return Post("/orders/designate",data)
}

export function cancelDesignateOrders(data){
    return Post("/orders/cancelDesignate",data)
}

export function splitOrder(data){
    return Post("/orders/splitOrder",data)
}

export function changeOrderStore(data){
    return Post("/orders/changeOrderStore",data)
}

export function changeOrderStatus(data){
    return Post("/orders/changeOrderStatus",data)
}   

export function mergeOrder(data){
    return Post('/orders/mergeOrder',data)
}

export function getMerge(data){
    return Get("/orders/getMerge",data)
}

export function getCanMerge(data){
    return Get('/orders/getCanMerge',data)
}

export function shelveOrder(data){
    return Post('/orders/shelveOrder',data)
}

export function userGetOrder(data){
    return Get("/orders/userGetOrder",data)
}

export function getUserChildOrder(data){
    return Get('/orders/userChildOrder',data)
}