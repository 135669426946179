<template>
  <div>
    <van-sticky>
      <div class="title">订单</div>
    </van-sticky>
    <van-tabs v-model="active">
      <van-empty description="暂无数据" v-if="this.statusList[this.active].data.length == 0" />
      <van-tab v-for="item in statusList" :key="item.orderStatus" :title="item.title"
        :badge="item.data.length != 0 && item.data != null ? item.data.length : ''">
        <div class="orderList">
          <div class="shopCard" v-for="shop in item.data">
            <div class="cardId">
              <span style="text-align:left;font-size: 16px">编号：{{ shop.orderId }}</span>
              <span style="float: right;font-size: 14px;color: rgb(165,35,136);font-weight: 600;">{{
                statusList.find(d => d.orderStatus === shop.orderStatus).name }}</span>
            </div>
            <div class="info">
              <div class="infoLeft">
                <img src="@/assets/card1.png" alt="" width="180px" height="140px">
                <div style="margin-top: 20px;font-size: 14px;">下单：{{ shop.createTime }}</div>
                <div style="margin-top: 10px;font-size: 14px;">更新：{{ shop.updateTime }}</div>
                <div class="profit">利润：{{ shop.profit }}</div>
                <div class="profit">上架费：{{ shop.listingFee }}</div>
              </div>
              <div class="infoCo">
                <div style="font-weight: 800;">{{ shop.orderName }}</div>
                <div style="color: rgb(222,123,61);">价格：{{ shop.money }}</div>
                <div>{{ shop.seller.realName }} <div class="seller">卖家</div>
                </div>
                <div>邀请码：{{ shop.seller.inviteCode }}
                  <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                    @click="copyInviteCode(shop.seller.inviteCode)" />
                </div>
                <div>{{ shop.buyer.realName }} <div class="buyer">买家</div>
                </div>
                <div>邀请码：{{ shop.buyer.inviteCode }}
                  <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                    @click="copyInviteCode(shop.buyer.inviteCode)" />
                </div>
                <div style="color:rgb(266,87,60);font-weight:700;text-align: right;">实付</div>
                <div style="color:rgb(266,87,60);font-weight:700;text-align: right;">￥{{ shop.money }}
                </div>
              </div>
            </div>
            <div class="buttonList">
              <van-button v-if="shop.orderStatus == 1 && shop.buyerId==userinfo.uid" color="rgb(165,35,136)" plain
                @click="showPayM(shop)">确认已付款</van-button>
              <van-button v-if="shop.orderStatus == 2 && shop.sellerId==userinfo.uid" color="rgb(165,35,136)" plain
                @click="showGetM(shop)">确认已收款</van-button>
              <van-button style="margin-left: 5px;" color="rgb(165,35,136)" plain
                @click="getSellInfo(shop)">收款信息</van-button>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-action-sheet v-model="showSellInfo" title="收款信息">
      <van-cell-group inset>
        <van-field v-if="sellInfo.wechatPic && sellInfo.wechatPic != ''" name="wechatPic" label="微信收款码:"
          label-width="10em" center input-align="right">
          <template #input>
            <van-image width="6rem" height="6rem" :src="sellInfo.wechatPic" />
          </template>
        </van-field>
        <van-field v-if="sellInfo.alipayAccount && sellInfo.alipayAccount != ''" name="alipayAccount" label-width="10em"
          border placeholder="请输入支付宝账号" center readonly v-model="sellInfo.alipayAccount" label="支付宝账号:">
          <template #right-icon>
            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.alipayAccount)" />
          </template>
        </van-field>
        <van-field v-if="sellInfo.alipayPic && sellInfo.alipayPic != ''" name="alipayPic" label-width="10em"
          label="支付宝收款码:" input-align="right" center>
          <template #input>
            <van-image width="6rem" height="6rem" :src="sellInfo.alipayPic" />
          </template>
        </van-field>
        <van-field v-if="sellInfo.chainName && sellInfo.chainName != ''" name="chainName" label-width="10em" border
          placeholder="请输入交易网络链" center v-model="sellInfo.chainName" label="交易网络链:" readonly>
          <template #right-icon>
            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.chainName)" />
          </template>
        </van-field>
        <van-field v-if="sellInfo.chainAddress && sellInfo.chainAddress != ''" name="chainAddress" label-width="10em"
          border placeholder="请输入USDT钱包地址" center v-model="sellInfo.chainAddress" label="USDT钱包地址:" readonly>
          <template #right-icon>
            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.chainAddress)" />
          </template>
        </van-field>
        <van-field v-if="sellInfo.chainPic && sellInfo.chainPic != ''" name="chainPic" label-width="10em" label="钱包二维码:"
          input-align="right" center>
          <template #input>
            <van-image width="6rem" height="6rem" :src="sellInfo.chainPic" />
          </template>
        </van-field>
        <van-field v-if="sellInfo.bankName && sellInfo.bankName != ''" name="bankName" label-width="10em" border
          placeholder="请输入银行名称" center v-model="sellInfo.bankName" label="银行名称:" readonly>
          <template #right-icon>
            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankName)" />
          </template>
        </van-field>
        <van-field v-if="sellInfo.bankAddress && sellInfo.bankAddress != ''" name="bankAddress" label-width="10em"
          border placeholder="请输入开户行名称" center v-model="sellInfo.bankAddress" label="开户行名称:" readonly>
          <template #right-icon>
            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankAddress)" />
          </template>
        </van-field>
        <van-field v-if="sellInfo.bankCard && sellInfo.bankCard != ''" name="bankCard" label-width="10em" border
          placeholder="请输入银行卡号" center v-model="sellInfo.bankCard" label="银行卡号:" readonly>
          <template #right-icon>
            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankCard)" />
          </template>
        </van-field>
        <div style="height: 30px;"></div>
      </van-cell-group>
    </van-action-sheet>
    <van-dialog v-model="showPay" title="确认付款" @confirm="confirmPay" message="是否确认已付款" show-cancel-button>
    </van-dialog>
    <van-dialog v-model="showGet" title="确认收款" @confirm="confirmGet" message="是否确认已收款" show-cancel-button>
    </van-dialog>
    <div style="height: 30px;"></div>
  </div>
</template>
<script>
import { userGetOrder,changeOrderStatus } from '@/api/orders';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';

export default {
  name: 'order',
  data() {
    return {
      active: 0,
      statusList: [
        {
          orderStatus: 4,
          title: '全部',
          data: []
        },
        {
          orderStatus: 1,
          title: '待付款',
          name: '等待买家付款',
          data: []
        },
        {
          orderStatus: 2,
          title: '待收款',
          name: '等待卖家确认',
          data: []
        },
        {
          orderStatus: 3,
          title: '已完成',
          name: '交易完成',
          data: []
        }
      ],
      copyIcon: require("@/assets/copy.png"),
      sellInfo: {},
      showSellInfo: false,
      showPay: false,
      showGet: false,
      shopInfo: {}
    }
  },
  computed: {
    ...mapGetters(['userinfo'])
  },
  watch: {

  },
  mounted() {
    this.getFourList()
  },
  methods: {
    async confirmPay(){
      let data = {...this.shopInfo}
      data.orderStatus = 2
      await changeOrderStatus(data).then(res=>{
        Notify({type:res.data.success?'success':'danger',message:res.data.msg})
      })
      this.getFourList()
    },
    async confirmGet(){
      let data = {...this.shopInfo}
      data.orderStatus = 3
      await changeOrderStatus(data).then(res=>{
        Notify({type:res.data.success?'success':'danger',message:res.data.msg})
      })
      this.getFourList()
    },
    showPayM(shop) {
      this.shopInfo = shop
      this.showPay = true
    },
    showGetM(shop) {
      this.shopInfo = shop
      this.showGet = true
    },
    getSellInfo(shop) {
      this.sellInfo = shop.seller
      this.showSellInfo = true
    },
    beforeChange(index) {
      let data = { orderStatus: this.statusList[index].orderStatus, uid: this.userinfo.uid }
      this.getOrderList(data, index)
      return new Promise((resolve) => {
        resolve(true);
      })
    },
    getFourList() {
      for (let i = 0; i < this.statusList.length; i++) {
        this.getOrderList({ orderStatus: this.statusList[i].orderStatus, uid: this.userinfo.uid }, i)
      }
    },
    async copyInviteCode(code) {
      try {
        await navigator.clipboard.writeText(code);
        Notify({ type: 'success', message: "复制成功" });
      } catch (err) {
        Notify({ type: 'danger', message: '复制失败' });
      }
    },
    async getOrderList(data, index) {
      await userGetOrder(data).then(res => {
        this.statusList[index].data = res.data.data != null ? res.data.data : []
      })
    }
  }
};
</script>
<style lang='less' scoped>
.title {
  background-color: rgb(165, 35, 136);
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: white;
  font-weight: 600;
}
/deep/ .van-tabs__wrap{
  position: sticky;
  top: 60px;
  z-index: 3;
}
.seller {
  background-color: rgb(217, 95, 45);
  color: #fff;
  padding: 5px;
  display: inline;
  border-radius: 8px;
  margin-left: 10px;
}

.buyer {
  background-color: rgb(150, 206, 240);
  color: #fff;
  padding: 5px;
  display: inline;
  border-radius: 8px;
  margin-left: 10px;
}

/deep/ [class*='van-hairline']::after {
  border: none;
}

.shopCard {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 90%;
  padding: 10px 10px;
  margin: 10px auto 0;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cardId {
  display: flex;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
  justify-content: space-between;
}

.infoCo {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 14px;
  justify-content: space-between;
}

.infoLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profit {
  background-color: bisque;
  color: rgb(254, 24, 24);
  padding: 8px;
  text-align: left;
  width: max-content;
  margin-top: 10px;
  border-radius: 6px;
}

.buttonList {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px;
}
</style>