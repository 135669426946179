<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" title="商品管理" left-arrow @click-left="onClickLeft" fixed placeholder safe-area-inset-top />
        </van-sticky>
        <van-tabs v-model="active" ref="tabs" :before-change="beforeChange">
            <van-tab v-for="item, index in shopList" :title="item.title" :key="item.id"
                :badge="item.data.length != 0 ? item.data.length : ''">
                <van-search class="searchO" v-model="searchValue" show-action placeholder="请输入手机号或邀请码"
                    @search="onSearch">
                    <template #action>
                        <div @click="onSearch(searchValue)">搜索</div>
                    </template>
                </van-search>
                <van-empty description="暂无数据" v-if="item.data.length == 0 && index == active" />
                <div class="shopCard" v-if="index == active" v-for="shop in item.data" :key="shop.id">
                    <div class="cardId">
                        <span style="text-align:left;font-size: 18px">编号：{{ shop.orderId }}</span>
                        <span style="float: right;font-size: 20px;color: blue;font-weight: 600;">{{
                            shopList.find(s => s.status === shop.status).title }}</span>
                    </div>
                    <div class="info">
                        <img src="@/assets/card1.png" alt="" width="180px" height="140px">
                        <div class="infoCo">
                            <div style="font-weight: 800;">旅游卡</div>
                            <div>持有人：{{ shop.buyer.realName }}</div>
                            <div>邀请码：{{ shop.buyer.inviteCode }}
                                <van-icon style="margin-left: 10px;" :name="copyIcon" size="20px"
                                    @click="copyInviteCode(shop.buyer.inviteCode)" />
                            </div>
                            <div>商品价格：<span style="color: red;">{{ shop.money }}</span></div>
                            <div>更新时间：{{ shop.updateTime }}</div>
                        </div>
                    </div>
                    <div class="buttonList" v-if="shop.status == 1">
                        <van-button color="rgb(165,35,136)" plain @click="toSomeBody(shop)">指派</van-button>
                        <van-button color="rgb(165,35,136)" plain @click="toSplit(shop)">拆分</van-button>
                        <van-button color="rgb(165,35,136)" plain @click="tochange(shop)">转场</van-button>
                        <van-button color="rgb(165,35,136)" plain @click="sealUp(shop)">封存</van-button>
                    </div>
                    <div class="buttonList" v-else>
                        <van-button color="rgb(165,35,136)" plain @click="sealUp(shop)">解封</van-button>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <van-action-sheet v-model="show" title="选择指派" @close="closeDesignate">
            <div style="padding: 60px 0;">
                <van-form>
                    <van-field v-model="inviteText" center clearable label="邀请码" placeholder="请输入邀请码">
                        <template #button>
                            <van-button size="small" type="primary" @click="searchDe">查询</van-button>
                        </template>
                    </van-field>
                    <van-field label="旅游团" :value="storeName" readonly />
                    <van-field label="指派给" :value="giveBody" readonly />
                    <div style="margin: 16px;">
                        <van-button round block type="info" @click="onSumbit">提交</van-button>
                    </div>
                </van-form>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model="split" title="选择拆分" @close="closeSplit">
            <div style="padding: 60px 0;">
                <van-field :value="balance" readonly label="剩余金额" />
                <van-form>
                    <van-field v-model="splitNumber" label="数量" placeholder="填入拆分数量" type="number"
                        :rules="[{ required: true }]">
                        <template #button>
                            <van-button size="small" type="primary" @click="getInput">确认</van-button>
                        </template>
                    </van-field>
                    <div v-for="(input, index) in inputs" :key="index">
                        <van-field v-model="inputs[index]" type="number" placeholder="输入金额"
                            :rules="getInputRules(index)">
                            <template #label>
                                金额{{ index + 1 }}
                            </template>
                        </van-field>
                    </div>
                    <div style="margin: 16px 16px 0 16px;">
                        <van-button round block type="info" @click="splittingOrder">提交</van-button>
                    </div>
                </van-form>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model="change" title="选择转场" @close="closeChange">
            <div style="padding: 60px 0;">
                <van-form>
                    <van-field v-model="inviteText" center clearable label="邀请码" placeholder="请输入邀请码">
                        <template #button>
                            <van-button size="small" type="primary" @click="searchByInvite">查询</van-button>
                        </template>
                    </van-field>
                    <van-field label="旅游团" :value="storeName" readonly />
                    <van-field label="转场给" :value="giveBody" readonly />
                    <div style="margin: 16px;">
                        <van-button round block type="info" @click="sumbitChangeStore">提交</van-button>
                    </div>
                </van-form>
            </div>
        </van-action-sheet>
        <van-dialog v-model="isSealUp" :title="this.active == 0 ? '封存' : '上架'" show-cancel-button @confirm="sealUpShop">
            <div style="margin: 20px 0;">确认{{ this.active == 0 ? '封存' : '上架' }}该商品吗</div>
        </van-dialog>
        <div style="height: 30px;"></div>
    </div>
</template>
<script>
import { changeOrderStatus, changeOrderStore, designateOrders, getOrdersByStatus, getOrdersByStore, splitOrder } from '@/api/orders';
import { getStore } from '@/api/store';
import { getUserByInvite } from '@/api/user';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';
import Decimal from 'decimal.js';

export default {
    name: 'travelCard',
    data() {
        return {
            storeName: '',
            inputs: [],
            inviteText: '',
            isSealUp: false,
            storeColumns: [],
            change: false,
            changeStoreName: '',
            changeStore: false,
            split: false,
            splitNumber: null,
            searchValue: '',
            giveBody: '',
            columns: [],
            show: false,
            user: {},
            active: 0,
            copyIcon: require("@/assets/copy.png"),
            shopList: [{
                id: 1,
                status: 1,
                title: '正常',
                data: [],
            }, {
                id: 2, status: 0,
                title: '封存',
                data: []
            }],
            order: {
                money: 0
            },
            storeList: [],
        }
    },
    computed: {
        ...mapGetters(['userinfo']),
        balance() {
            let inputPrice = this.inputs.reduce((sum, amount) => {
                // 使用 parseFloat 进行预处理，确保 amount 是数值类型
                const numericValue = parseFloat(amount || 0);
                // 将数值转换为 Decimal 对象
                return sum.plus(new Decimal(numericValue.toString()));
            }, new Decimal(0));
            // 计算剩余金额
            return new Decimal(this.order.money).minus(inputPrice).toNumber();
        }
    },
    watch: {
    },
    mounted() {
        for (let i = 0; i < this.shopList.length; i++) {
            let data = {
                storeCode: this.userinfo.storeCode,
                status: this.shopList[i].status,
                orderStatus: 0
            }
            this.getTarvelCard(data, i)
        }
    },
    methods: {
        getInputRules(index) {
            return [
                {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message: '最多填写两位小数',
                    validator: async (value, rule) => {
                        try {
                            // 预处理输入值，确保其为有效的数值
                            const numericValue = parseFloat(value);

                            // 检查是否为负数
                            if (numericValue < 0) {
                                throw new Error('金额不能为负数');
                            }

                            // 将处理后的值转换为 Decimal 对象
                            const inputValue = new Decimal(numericValue.toString());

                            // 获取剩余金额的 Decimal 对象
                            const remainingBalance = new Decimal(this.balance);

                            // 比较输入金额和剩余金额
                            if (inputValue.greaterThan(remainingBalance)) {
                                throw new Error('输入金额不能大于剩余金额');
                            }

                            // 验证通过，返回 true 或者不返回任何值
                            return true;
                        } catch (error) {
                            // 验证失败，返回错误信息
                            return error.message;
                        }
                    }
                }

            ];
        },
        getInput() {
            this.inputs = Array.from({ length: this.splitNumber }, () => '')
        },
        async searchDe() {
            if (this.inviteText.trim() != '') {
                await getUserByInvite({ inviteCode: this.inviteText }).then(res => {
                    if (res.data.data == null || res.data.data.storeCode != this.order.storeCode ||
                        res.data.data.uid == this.order.buyerId
                    ) {
                        Notify({ type: 'danger', message: '请输入正确的邀请码' })
                    } else {
                        this.giveBody = res.data.data.realName
                        this.storeName = res.data.data.storeName
                        this.user = res.data.data
                    }
                })
            } else {
                Notify({ type: "warning", message: '请输入被指派人员的邀请码' })
            }
        },
        async searchByInvite() {
            if (this.inviteText.trim() != '') {
                await getUserByInvite({ inviteCode: this.inviteText }).then(res => {
                    if (res.data.data == null || res.data.data.storeCode == this.order.storeCode) {
                        Notify({ type: 'danger', message: '请输入正确的邀请码' })
                    } else {
                        this.giveBody = res.data.data.realName
                        this.storeName = res.data.data.storeName
                        this.user = res.data.data
                    }
                })
            } else {
                Notify({ type: "warning", message: '请输入被指派人员的邀请码' })
            }
        },
        sealUp(shop) {
            this.order = { ...shop }
            this.order.status = this.order.status == 0 ? 1 : 0
            this.isSealUp = true
        },
        async sealUpShop() {
            await changeOrderStatus(this.order).then(res => {
                Notify({ 'type': res.data.success ? 'success' : 'danger', message: res.data.msg })
                this.isSealUp = false
                this.order = {money:0}
                for (let i = 0; i < this.shopList.length; i++) {
                    let data = {
                        storeCode: this.userinfo.storeCode,
                        status: this.shopList[i].status,
                        orderStatus: 0
                    }
                    this.getTarvelCard(data, i)
                }
            })
        },
        tochange(shop) {
            this.getStoreAll()
            this.order = { ...shop }
            this.change = true
        },
        closeChange() {
            this.change = false
            this.order = {money:0}
            this.storeName = ''
            this.giveBody = ''
            this.inviteText = ''
        },
        //转场
        async sumbitChangeStore() {
            if (this.storeName != '') {
                Object.assign(this.order, {
                    changeStore: this.user.storeCode,
                    changeUserId: this.user.uid
                })
                await changeOrderStore(this.order).then(res => {
                    Notify({ 'type': res.data.success ? 'success' : 'danger', message: res.data.msg })
                    this.closeChange()
                    let data = {
                        storeCode: this.userinfo.storeCode,
                        status: this.shopList[this.active].status,
                        orderStatus: 0
                    }
                    this.getTarvelCard(data, this.active)
                })
            }
        },
        async getStoreAll() {
            await getStore().then(res => {
                let list = res.data.data
                this.storeList = list.filter(item => item.storeCode !== this.userinfo.storeCode)
                this.storeColumns = this.storeList.map(item => item.storeName)
            })
        },
        toSplit(shop) {
            this.order = { ...shop }
            this.split = true
        },
        //拆分
        async splittingOrder() {
            if (this.balance!=0) {
                Notify({ type: 'danger', message: '拆分金额与现实逻辑相悖' });
                return;
            }
            let totalAmount = this.inputs.reduce((sum, amount) => sum.plus(new Decimal(amount || 0)), new Decimal(0));
            const numberList = this.inputs.map(input => new Decimal(input));
            for (let i = 0; i < numberList.length; i++) {
                if (numberList[i] <= 0) {
                    Notify({ type: 'danger', message: '拆分金额与现实逻辑相悖' })
                    return;
                }
            }
            if (totalAmount != this.order.money) {
                Notify({ type: 'danger', message: '拆分金额不对' })
            } else {
                Object.assign(this.order, {
                    splitNumber: this.splitNumber,
                    priceList: numberList
                })
                await splitOrder(this.order).then(res => {
                    Notify({ 'type': res.data.success ? 'success' : 'danger', message: res.data.msg })
                    this.closeSplit()
                    let data = {
                        storeCode: this.userinfo.storeCode,
                        status: this.shopList[this.active].status,
                        orderStatus: 0
                    }
                    this.getTarvelCard(data, this.active)
                })
            }
        },
        closeSplit() {
            this.splitNumber = ""
            this.split = false
            this.order = { money: new Decimal(0) }
            this.inputs = []
        },
        onSearch(value) {
            if (value != '') {
                let orderList = this.shopList[this.active].data;
                let length = orderList.length;
                let list = []
                if (length != 0) {
                    for (let i = 0; i < length; i++) {
                        if (value == orderList[i].buyer.username || value == orderList[i].buyer.inviteCode ||
                            value == orderList[i].seller.username || value == orderList[i].seller.inviteCode
                        ) {
                            list.push(orderList[i])
                        }
                    }
                }
                this.shopList[this.active].data = list
            } else {
                let data = {
                    storeCode: this.userinfo.storeCode,
                    status: this.shopList[this.active].status,
                    orderStatus: 0
                }
                this.getTarvelCard(data, this.active)
            }
        },
        //指派
        async onSumbit() {
            if (this.giveBody != '' && this.user.uid != this.order.buyerId) {
                Object.assign(this.order, {
                    designateId: this.user.uid
                })
                await designateOrders(this.order).then(res => {
                    Notify({ 'type': res.data.success ? 'success' : 'danger', message: res.data.msg })
                    this.closeDesignate()
                    let data = {
                        storeCode: this.userinfo.storeCode,
                        status: this.shopList[this.active].status,
                        orderStatus: 0
                    }
                    this.getTarvelCard(data, this.active)
                })
            } else {
                Notify({ type: 'warning', message: '请选择除拥有者外的指派对象' })
            }
        },
        toSomeBody(shop) {
            this.order = { ...shop }
            this.order.orderStatus = 1
            this.show = true
        },
        closeDesignate() {
            this.giveBody = ""
            this.show = false
            this.order = {money:0}
            this.storeName = ''
            this.inviteText = ''
        },
        beforeChange(index) {
            let data = {
                storeCode: this.userinfo.storeCode,
                status: this.shopList[index].status,
                orderStatus: 0
            }
            this.getTarvelCard(data, index)
            return new Promise((resolve) => {
                resolve(true);
            })
        },
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
        onClickLeft() {
            this.$router.back()
        },
        async getTarvelCard(data, index) {
            await getOrdersByStore(data).then(res => {
                this.shopList[index].data = res.data.data
            })
        }
    }
};
</script>
<style lang='less' scoped>
/deep/ [class*='van-hairline']::after {
    border: none;
}

/deep/ .van-tabs__wrap {
    position: sticky;
    top: 46px;
    z-index: 3;
}

.searchO {
    position: sticky;
    top: 90px;
    z-index: 3;
}

.shopCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 85%;
    padding: 20px;
    margin: 20px auto 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cardId {
    display: flex;
    justify-content: space-between;
}

.info {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    justify-content: space-between;
}

.infoCo {
    display: flex;
    flex-direction: column;
    text-align: left;

    div {
        margin-bottom: 8px;
    }
}

.buttonList {
    display: flex;
    justify-content: space-between;
}
</style>