import Vue from 'vue'
import {
    Tabbar, TabbarItem, NavBar, Button, Form, Field, Icon, Notify, Swipe, SwipeItem, Grid, GridItem, Sticky,
    Tab, Tabs, Cell, CellGroup,Uploader,Divider,Col, Row,Card,Switch,ActionSheet,Picker,Popup,Empty,Dialog,
    Search,Checkbox,CheckboxGroup,Image as VanImage,Radio,RadioGroup
} from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(VanImage);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Card)
Vue.use(Search);
Vue.use(Dialog);
Vue.use(Empty)
Vue.use(Popup);
Vue.use(ActionSheet);
Vue.use(Picker);
Vue.use(Switch);
Vue.use(Row)
Vue.use(Col)
Vue.use(Divider)
Vue.use(Uploader);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Swipe);
Vue.use(Sticky);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(SwipeItem);
Vue.use(Notify)
Vue.use(Icon);
Vue.use(Form);
Vue.use(Field);
Vue.use(NavBar)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Button);
Notify.setDefaultOptions({duration:1000})