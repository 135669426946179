import { Get,Post,Put,Delete,request } from "@/request/request";
export function getUserInfo(){
    return Get("/user/info")
}
export function changePassword(data){
    return Get("/user/changePassword",data)
}
export function updateUser(data) {
    return request({
        url:"/user/updateUser",
        data,
        method:'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
  }
export function getAllUserInfo(){
    return Get("/user/getAllUser")
}

export function findLeader(){
    return Get("/user/findLeader")
}

export function getStoreUser(data){
    return Get("/user/getStoreUser",data)
}

export function getUserByInvite(data){
    return Get("/user/getUserByInvite",data)
}

export function getInviteNumObj(data){
    return Get("/user/getInviteNum",data)
}

export function getUserNumByStore(data){
    return Get("/user/getUserNumByStore",data)
}

export function getUserByUserName(data){
    return Get("/user/getUserByUserName",data)
}

export function changeUserStoreInM(data){
    return Post("/user/changeUserStore",data)
}

export function deleteUser(data){
    return Delete("/user/deleteUser",data)
}