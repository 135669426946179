import { Get, Post } from "@/request/request";

export function getUserWithDrawn(data){
    return Get('/withdraw/userWithDrawn',data)
}

export function createWithDraw(data){
    return Post("/withdraw/createWithDraw",data)
}

export function userWithDraw(data){
    return Get('/withdraw/userWithDraw',data)
}

export function withDrawByStore(data){
    return Get("/withdraw/withDrawByStore",data)
}

export function changeWithDraw(data){
    return Post("/withdraw/changeWithDraw",data)
} 

export function getLegerData(data){
    return Get("/withdraw/getLegerData",data)
}