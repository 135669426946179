import { Get,Post,Put,Delete } from "@/request/request";
export function login(data){
    return Post("/login",data)
}
export function register(data){
    return Post("/register",data)
}
export function layout(){
    return Post("/logout")
}

export function testToken(){
    return Get("/testToken")
}