import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home.vue';
import Login from '@/views/login.vue';
import Index from '@/views/HomeView/index.vue';
import Order from '@/views/HomeView/order.vue';
import Ledger from '@/views/HomeView/ledger.vue';
import User from '@/views/HomeView/user.vue';
import Settings from '@/views/HomeView/settings.vue';
import ChangePassword from '@/views/HomeView/changePassword.vue';
import ChangeUser from '@/views/HomeView/changeUser.vue';
import StoreManage from '@/views/HomeView/storeManage.vue';
import TravelGroup from '@/views/HomeView/travelGroup.vue';
import TravelOrder from '@/views/HomeView/travelOrder.vue';
import TravelCard from '@/views/HomeView/travelCard.vue';
import InviteFriend from '@/views/HomeView/inviteFriend.vue';
import AdminOrder from '@/views/HomeView/adminOrder.vue';
import MergeOrder from '@/views/HomeView/mergeOrder.vue';
import ShelveOrder from '@/views/HomeView/shelveOrder.vue';
import UserWithDraw from '@/views/HomeView/userWithDraw.vue';
import ManageWithDraw from '@/views/HomeView/manageWithDraw.vue';
import UserFan from '@/views/HomeView/userFan.vue';
import StoreOrder from "@/views/HomeView/storeOrder.vue";
import Tally from "@/views/HomeView/tally.vue";
import AdminManageOrder from "@/views/HomeView/adminManageOrder.vue"
import AdminTally from '@/views/HomeView/adminTally.vue'
import adminUser from '@/views/HomeView/adminUser.vue'
import UserSolitaire from '@/views/HomeView/userSolitaire.vue'
import MSolitaire from '@/views/HomeView/manageSolitaire.vue'
import Bonus from "@/views/HomeView/bonus.vue"
import UserChildOrder from '@/views/HomeView/userChildOrder.vue'
import StoreUser from '@/views/HomeView/storeUser.vue'

import { Notify } from 'vant';
import { testToken } from '@/api/login';
import { getUserInfo } from '@/api/user';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '首页'
        },
        component: Index
      }, {
        path: 'order',
        name: 'order',
        meta: {
          title: '全部记录'
        },
        component: Order
      }, {
        path: 'ledger',
        name: 'ledger',
        meta: {
          title: '记账本'
        },
        component: Ledger
      },
      {
        path: 'user',
        name: 'user',
        meta: {
          title: '个人中心'
        },
        component: User
      },
    ]
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: ChangePassword
  },
  {
    path: '/changeUser',
    name: 'changeUser',
    component: ChangeUser
  },
  {
    path: '/storeManage',
    name: 'storeManage',
    component: StoreManage
  }, {
    path: '/travelGroup',
    name: 'travelGroup',
    component: TravelGroup
  },
  {
    path: '/travelOrder',
    name: 'travelOrder',
    component: TravelOrder
  }, {
    path: '/travelCard',
    name: 'travelCard',
    component: TravelCard
  },
  {
    path: '/inviteFriend',
    name: 'inviteFriend',
    component: InviteFriend
  },
  {
    path: '/adminOrder',
    name: 'adminOrder',
    component: AdminOrder
  },
  {
    path: '/mergeOrder',
    name: 'mergeOrder',
    component: MergeOrder
  },
  {
    path: '/shelveOrder',
    name: 'shelveOrder',
    component: ShelveOrder
  },
  {
    path: '/userWithDraw',
    name: 'userWithDraw',
    component: UserWithDraw
  }, {
    path: '/manageWithDraw',
    name: 'manageWithDraw',
    component: ManageWithDraw
  }, {
    path: '/userFan',
    name: 'userFan',
    component: UserFan
  }, {
    path: '/storeOrder',
    name: 'storeOrder',
    component: StoreOrder
  }, {
    path: '/tally',
    name: 'tally',
    component: Tally
  },{
    path:'/adminManageOrder',
    name:'adminManageOrder',
    component: AdminManageOrder
  },{
    path:'/adminTally',
    name:'adminTally',
    component: AdminTally
  },
  {
    path:'/adminUser',
    name:'adminUser',
    component: adminUser
  },{
    path:'/userSolitaire',
    name:'userSolitaire',
    component: UserSolitaire
  },{
    path:'/mSolitaire',
    name:'mSolitaire',
    component: MSolitaire
  },{
    path:'/bonus',
    name:'bonus',
    component: Bonus
  },{
    path:'/userChildOrder',
    name:'userChildOrder',
    component: UserChildOrder
  },{
    path:'/storeUser',
    name:'storeUser',
    component: StoreUser
  }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: 'ignore',
});

router.beforeEach(async (to, from, next) => {
  // 直接跳转到登录页或从登录页跳转的情况直接允许
  if (from.path === '/login' || to.path === '/login') {
    next();
    return;
  }

  try {
    const userInfo = await getUserInfo();
    if (userInfo.data.code === 401) {
      // 用户未认证，跳转到登录页
      Notify({ type: 'danger', message: userInfo.data.msg });
      if (to.path !== '/login') {
        router.replace("/login"); // 确保重定向一次并替换当前导航
      } else {
        next();
      }
    } else {
      store.dispatch("ADD_USERINFO",userInfo.data.data)
      // 用户已认证，允许继续路由跳转
      next();
    }
  } catch (error) {
    // 异步操作失败，处理错误情况
    Notify({ type: 'danger', message: 'token过期了，请重新登录' });
    router.replace("/login"); // 阻止路由跳转
  }
});

export default router;