import axios from "axios";
import Vue from "vue";
import NProgress from "nprogress";
// import { getAvailableBaseURL } from './domainConfig.js';
import "nprogress/nprogress.css";
import store from "@/store";
axios.defaults.withCredentials = true; //让axios携带cookie
Vue.prototype.$axios = axios;
// let baseURL;
// (async () => {
//     baseURL = await getAvailableBaseURL();
// })();
export function request(config) {
    // 创建axios的实例
    const instance = axios.create({
        // baseURL:baseURL,
        baseURL: "https://240707.xyz/api",
        // baseURL: "http://localhost:8080",
        timeout: 5000,
        withCredentials: true
    });
    instance.interceptors.request.use(config => {
        NProgress.start();
        let token = store.state.token;
        if (token) {
            config.headers.Authorization = token
        }
        return config;
    });

    instance.interceptors.response.use(response => {
        NProgress.done();
        return response
    }
    );

    // 发送真正的网络请求
    return instance(config);
}

export const Get = (url, data) =>
    request({
        url,
        params: data,
        method: "get"
    });
export const Post = (url, data) =>
    request({
        url,
        data,
        method: "post"
    });
export const Delete = (url, data) =>
    request({
        url,
        data,
        method: "delete"
    });
export const Put = (url, data) =>
    request({
        url,
        data,
        method: "put"
    });
