<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" title="并单管理" left-arrow @click-left="onClickLeft" fixed placeholder safe-area-inset-top />
        </van-sticky>
        <van-tabs v-model="active" ref="tabs">
            <van-search class="searchO" v-model="searchValue" show-action placeholder="请输入手机号或邀请码" @search="onSearch">
                <template #action>
                    <div @click="onSearch(searchValue)">搜索</div>
                </template>
            </van-search>
            <van-empty description="暂无数据" v-if="this.shopList[this.active].data.length == 0" />
            <van-tab v-for="item in shopList" :key="item.orderStatus" :title="item.title"
                :badge="item.data.length != 0 && item.data != null ? item.data.length : ''">
                <div class="orderList">
                    <div class="shopCard" v-for="shop in item.data">
                        <div class="cardId">
                            <span style="text-align:left;font-size: 16px">编号：{{ shop.orderId }}</span>
                            <span style="float: right;font-size: 14px;color: rgb(165,35,136);font-weight: 600;">{{
                                item.title
                                }}</span>
                        </div>
                        <div class="info">
                            <div>
                                <img src="@/assets/card1.png" alt="" width="180px" height="140px">
                                <div style="margin-top: 20px;font-size: 14px;">下单：{{ shop.createTime }}</div>
                                <div style="margin-top: 10px;font-size: 14px;">更新：{{ shop.updateTime }}</div>
                                <div></div>
                            </div>
                            <div class="infoCo">
                                <div style="font-weight: 800;">{{ shop.orderName }}</div>
                                <div style="color: rgb(222,123,61);">价格：{{ shop.money }}</div>
                                <div v-if="shop.seller">{{ shop.seller.realName }} <div class="seller">卖家</div>
                                </div>
                                <div v-else>管理员<div class="seller">卖家</div>
                                </div>
                                <div v-if="shop.seller">邀请码：{{ shop.seller.inviteCode }}
                                    <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                                        @click="copyInviteCode(shop.seller.inviteCode)" />
                                </div>
                                <div v-else>邀请码：XAYNAQ
                                    <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                                        @click="copyInviteCode('XAYNAQ')" />
                                </div>
                                <div>{{ shop.buyer.realName }} <div class="buyer">买家</div>
                                </div>
                                <div>邀请码：{{ shop.buyer.inviteCode }}
                                    <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                                        @click="copyInviteCode(shop.buyer.inviteCode)" />
                                </div>
                                <div style="color:rgb(266,87,60);font-weight:700;text-align: right;">实付</div>
                                <div style="color:rgb(266,87,60);font-weight:700;text-align: right;">￥{{ shop.money }}
                                </div>
                            </div>
                        </div>
                        <div class="buttonList">
                            <van-checkbox shape="square" v-if="item.id == 1"
                                v-model="shop.isMerge">勾选需要合并的订单</van-checkbox>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <div class="confirmMerge" @click="openShow" v-if="this.active == 0">
            确定并单
        </div>
        <van-dialog v-model="show" title="并单" @confirm="confirmMerge" message="是否确认并单，一旦合并无法撤回" show-cancel-button>
        </van-dialog>
        <div style="height: 30px;"></div>
    </div>
</template>
<script>
import { getCanMerge, getMerge, mergeOrder } from '@/api/orders';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';
export default {
    name: 'mergeOrder',
    data() {
        return {
            active: 0,
            copyIcon: require("@/assets/copy.png"),
            shopList: [{
                id: 1,
                title: '可并单',
                data: [],
            }, {
                id: 2,
                title: '已并单',
                data: []
            }],
            show: false,
            searchValue: ''
        }
    },
    computed: {
        ...mapGetters(['userinfo'])
    },
    mounted() {
        this.getCanMergeList()
    },
    methods: {
        onSearch(value) {
            if (value != '') {
                let orderList = this.shopList[this.active].data;
                let length = orderList.length;
                let list = []
                if (length != 0) {
                    for (let i = 0; i < length; i++) {
                        if (value == orderList[i].buyer.username || value == orderList[i].buyer.inviteCode
                            || value == orderList[i].seller.username || value == orderList[i].seller.inviteCode
                        ) {
                            list.push(orderList[i])
                        }
                    }
                }
                this.shopList[this.active].data = list
            } else {
                this.getCanMergeList()
            }
        },
        async confirmMerge() {
            let data = []
            for (let i = 0; i < this.shopList[0].data.length; i++) {
                if (this.shopList[0].data[i].isMerge) {
                    data.push(this.shopList[0].data[i])
                    data[i].isMerge = 1
                }
            }
            if (data.length == 0 || data.length == 1) {
                Notify({ type: 'danger', message: '请选择至少两个商品' })
            } else {
                await mergeOrder(data).then(res => {
                    Notify({ type: res.data.success ? 'success' : 'danger', message: res.data.msg })
                    this.getCanMergeList()
                })
            }
        },
        openShow() {
            this.show = true
        },
        async getCanMergeList() {
            for (let i = 0; i < 2; i++) {
                if (i == 0) {
                    await getCanMerge({ storeCode: this.userinfo.storeCode }).then(res => {
                        this.shopList[0].data = res.data.data
                    })
                } else {
                    await getMerge({ storeCode: this.userinfo.storeCode }).then(res => {
                        this.shopList[1].data = res.data.data
                    })
                }
            }
        },
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
        onClickLeft() {
            this.$router.back()
        },
    }
};
</script>
<style lang='less' scoped>
/deep/ .van-tabs__wrap{
  position: sticky;
  top: 46px;
  z-index: 3;
}
.searchO{
    position: sticky;
    top: 90px;
    z-index: 3;
}
.seller {
    background-color: rgb(217, 95, 45);
    color: #fff;
    padding: 5px;
    display: inline;
    border-radius: 8px;
    margin-left: 10px;
}

.buyer {
    background-color: rgb(150, 206, 240);
    color: #fff;
    padding: 5px;
    display: inline;
    border-radius: 8px;
    margin-left: 10px;
}

/deep/ [class*='van-hairline']::after {
    border: none;
}

.shopCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 90%;
    padding: 10px 10px;
    margin: 10px auto 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cardId {
    display: flex;
    justify-content: space-between;
}

.info {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 0;
    justify-content: space-between;
}

.infoCo {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;

    div {
        margin-bottom: 10px;
    }
}

.buttonList {
    display: flex;
    justify-content: space-evenly;
}

.confirmMerge {
    position: fixed;
    bottom: 15vh;
    background-color: rgb(200, 179, 130);
    color: #fff;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    padding: 15px;
    left: 20px;
    z-index: 99;
}
</style>