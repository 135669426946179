<template>
    <div>
        <router-view />
    <div class="bottom-padding"></div>
        <van-tabbar :safe-area-inset-bottom="true" route>
            <van-tabbar-item replace to="/home/index" icon="home-o">首页</van-tabbar-item>
            <van-tabbar-item replace to="/home/order" icon="orders-o">全部记录</van-tabbar-item>
            <van-tabbar-item replace to="/home/ledger" icon="gold-coin-o">记账本</van-tabbar-item>
            <van-tabbar-item replace to="/home/user" icon="user-o">个人中心</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>

export default {
    name: '',
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
};
</script>
<style lang='less' scoped>
.bottom-padding{
  height: 70px;
}
</style>