<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" title="订单初始化" left-arrow @click-left="onClickLeft" />
            <div>
                <van-field v-model="storeName" center clearable placeholder="请输入旅游团名称">
                    <template #label>
                        <van-button type="info" @click="show = !show">添加订单</van-button>
                    </template>
                    <template #button>
                        <van-button round type="info" icon="search" @click="findByStoreName"></van-button>
                    </template>
                </van-field>
            </div>
        </van-sticky>
        <div class="orderList">
            <div class="shopCard" v-for="shop in orderList">
                <div class="cardId">
                    <span style="text-align:left;font-size: 16px">编号：{{ shop.orderId }}</span>
                    <span
                        style="float: right;font-size: 18px;color: blue;font-weight: 600;">{{ shop.status==1?'已上架':'封存' }}</span>
                </div>
                <div class="info">
                    <img src="@/assets/card1.png" alt="" width="180px" height="140px">
                    <div class="infoCo">
                        <div style="font-weight: 800;">{{shop.orderName}}</div>
                        <div>持有人：{{ shop.buyer.realName }}</div>
                        <div>邀请码：{{ shop.buyer.inviteCode }}
                            <van-icon style="margin-left: 10px;" :name="copyIcon" size="20px"
                                @click="copyInviteCode(shop.buyer.inviteCode)" />
                        </div>
                        <div>商品价格：{{ shop.money }}</div>
                        <div>更新时间：{{ shop.updateTime }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="paddingBo"></div>
        <van-action-sheet v-model="show" title="初始化商品">
            <van-form @submit="initOrder">
                <van-field v-model="orderInfo.orderName" disabled name="orderName" label="订单名" placeholder="订单名"
                    :rules="[{ required: true, message: '请填写订单名' }]" />
                <van-field v-model="orderInfo.money" name="money" type="number" label="商品价格"
                    placeholder="商品价格" :rules="[{ required: true, message: '请填写商品价格' }]" />
                <van-field clickable name="buyerId" :value="value" label="团长" placeholder="点击选择团长"
                    @click="showPicker = true" :rules="[{ required: true, message: '请选择团长' }]" />
                <van-popup v-model="showPicker" position="bottom">
                    <van-picker show-toolbar :columns="columns" @confirm="onConfirmed" @cancel="showPicker = false" />
                </van-popup>
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </van-action-sheet>
    </div>
</template>
<script>
import { adminGetOrders, createOrder,adminOrdersByStore } from '@/api/orders';
import { findLeader } from '@/api/user';
import { Notify } from 'vant';

export default {
    name: 'travelGroup',
    data() {
        return {
            copyIcon: require("@/assets/copy.png"),
            show: false,
            storeName: '',
            orderList: [],
            checked: true,
            orderInfo: {
                orderName: '旅游卡',
                money: null,
                status: 1,
                buyerId:null,
                orderStatus:0,
                storeCode:""
            },
            value: '',
            userList: [],
            columns: [],
            showPicker: false,
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.getLeader();
        this.getOrderList();
    },
    methods: {
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
        async getLeader() {
            await findLeader().then(res => {
                this.userList = res.data.data;
                this.columns = this.userList.map(user => user.realName)
            })
        },
        async getOrderList() {
            await adminGetOrders().then(res => {
                this.orderList = res.data.data
            })
        },
        onConfirmed(value, index) {
            this.orderInfo.buyerId = this.userList[index].uid;
            this.orderInfo.storeCode = this.userList[index].storeCode
            this.value = value;
            this.showPicker = false
        },
        onClickLeft() {
            this.$router.back()
        },
        async initOrder() {
            await createOrder(this.orderInfo).then(res => {
                Notify({ 'type': res.data.success ? 'success' : 'danger', message: res.data.msg })
                this.value = ''
                this.orderInfo.money = null
                this.orderInfo.buyerId = null
                this.show = false
            })
            this.getOrderList()
        },
        async findByStoreName(){
            if(this.storeName.trim()!=''){
                await adminOrdersByStore({storeName:this.storeName}).then(res=>{
                this.orderList = res.data.data
            })
            }else{
                this.getOrderList()
            }
        }
    }
};
</script>
<style lang='less' scoped>
/deep/ .van-field__label {
    width: 7.4em;
}

/deep/ [class*='van-hairline']::after {
  border: none;
}
.shopCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 85%;
    padding: 10px 20px;
    margin: 10px auto 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cardId {
    display: flex;
    justify-content: space-between;
}

.info {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    justify-content: space-between;
}

.infoCo {
    display: flex;
    flex-direction: column;
    text-align: left;

    div {
        margin-bottom: 5px;
    }
}

.buttonList {
    display: flex;
    justify-content: space-between;
}

.paddingBo {
    height: 30px;
}
</style>