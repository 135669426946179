<template>
    <div>
        <van-nav-bar :border="false" title="修改密码" left-arrow @click-left="onClickLeft" />
        <div class="card">
            <van-form @submit="changeNewPassword">
                <van-cell-group inset>
                    <van-field v-model="password" type="password" label="原密码" placeholder="请输入原密码"
                        :rules="[{ required: true, message: '请填写原密码' }]" />
                    <van-field v-model="newPassword" type="password" label="新密码" placeholder="请输入新密码"
                        :rules="[{ required: true, message: '请填写新密码' }]" />
                </van-cell-group>
                <div class="changeButton">
                    <van-button round block color="rgb(166,84,138)" native-type="sumbit">修改密码</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
import { changePassword } from '@/api/user';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';

export default {
    name: 'changePassword',
    data() {
        return {
            password: '',
            newPassword: ''
        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    mounted() {

    },
    methods: {
        onClickLeft() {
            this.$router.back()
        },
        async changeNewPassword() {
            let data = {
                uid: this.userinfo.uid,
                password: this.password,
                newPassword: this.newPassword
            }
            await changePassword(data).then(res => {
                if (res.data.success) {
                    Notify({ type: 'success', message: res.data.msg })
                    this.onClickLeft()
                } else {
                    Notify({ type: 'danger', message: res.data.msg })
                }
            })
        }
    }
};
</script>
<style lang='less' scoped>
.card {
    margin-top: 30px;
}

.changeButton {
    width: 90%;
    margin: 40px auto 0;
}
</style>