<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" title="接龙管理" left-arrow @click-left="onClickLeft" fixed placeholder
                safe-area-inset-top />
        </van-sticky>
        <div class="allSolitaire">
            <div class="personNum">今日接龙人数:{{ SolitaireList.length }}
            </div>
        </div>
        <van-empty description="暂无数据" v-if="SolitaireList.length == 0" />
        <div class="solitareCard" v-for="c in SolitaireList">
            <van-cell-group inset>
                <van-cell :title-style="card" value-class="card" title="预约人:" :value="c.user.realName" />
                <van-cell :title-style="card" value-class="card" title="预约人手机号:" :value="c.user.username" />
                <van-cell :title-style="card" value-class="card" title="预约区间:" :value="priceArea[c.solitaireType]" />
                <van-cell :title-style="card" value-class="card" title="邀请码:">
                    <template #default>
                        {{ c.user.inviteCode }}
                        <van-button class="copyButton" size="small" @click="copyInviteCode(c.user.inviteCode)">
                            复制邀请码
                        </van-button>
                    </template>
                </van-cell>
                <van-cell :title-style="card" value-class="card" title="预约时间:" :value="c.createTime" />
                <van-cell :title-style="card" value-class="card" title="推荐人名称:" :value="c.parent.realName" />
                <van-cell :title-style="card" value-class="card" title="推荐人手机号:" :value="c.parent.username" />
            </van-cell-group>
        </div>
    </div>
</template>
<script>
import { getByStore } from '@/api/solitaire';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';
import { Dialog } from 'vant';
import { getUserInfo } from '@/api/user';
export default {
    name: 'manageSolitaire',
    data() {
        return {
            SolitaireList: [],
            priceArea: ["2000~5000", "5000~9000"],
            card: {
                textAlign: 'left',
            },
        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {
        this.getSolitaireByStore()
    },
    methods: {
        onInput(checked) {
            Dialog.confirm({
                title: '提醒',
                message: '是否切换开关？',
            }).then(() => {
                this.changeStoreWithOpen(checked);
            }).catch(() => {
            });
        },
        async findUserInfo() {
            await getUserInfo().then(res => {
                let userInfo = res.data.data
                this.$store.dispatch("ADD_USERINFO", userInfo)
            })
        },
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
        onClickLeft() {
            this.$router.back()
        },
        async getSolitaireByStore() {
            await getByStore({ storeCode: this.userinfo.storeCode }).then(res => {
                this.SolitaireList = res.data.data
            })
        }
    }
};
</script>
<style lang='less' scoped>
.solitareCard {
    margin-top: 30px;
    justify-content: center;
}

.card {
    text-align: left;
}

.copyButton {
    margin-left: 10px;
    background-color: rgb(165, 35, 136);
    color: #fff;
}

.personNum {
    background-color: rgb(165, 35, 136);
    color: #fff;
    font-size: 13px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    padding: 10px 0;
}

.solCard {
    background-color: #fff;
    width: 70%;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-weight: 600;
    text-align: left;

    div {
        margin-top: 10px;
    }

    margin: 20px auto;
}
</style>