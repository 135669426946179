<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" title="绑定信息" left-arrow @click-left="onClickLeft" />
        </van-sticky>
        <van-form @submit="onSubmit" style="margin-top: 20px;">
            <van-cell-group inset>
                <!-- 微信收款码上传 -->
                <van-field name="wechatPic" label="微信收款码:" label-width="10em" center input-align="right">
                    <template #input>
                        <van-uploader :after-read="afterReadWechat" :before-delete="deleteWechat"
                            v-model="wechatPicFile" max-count="1" />
                    </template>
                </van-field>
                <!-- 支付宝账号 -->
                <van-field name="alipayAccount" label-width="10em" border placeholder="请输入支付宝账号" center
                    v-model="userObj.alipayAccount" label="支付宝账号:" />
                <!-- 支付宝收款码上传 -->
                <van-field name="alipayPic" label-width="10em" label="支付宝收款码:" input-align="right" center>
                    <template #input>
                        <van-uploader :after-read="afterReadAlipay" :before-delete="deleteAlipay" max-count="1"
                            v-model="alipayPicFile" />
                    </template>
                </van-field>
            </van-cell-group>
            <van-cell-group style="margin-top: 20px;" inset>
                <!-- 交易网络链 -->
                <van-field name="chainName" label-width="10em" border placeholder="请输入交易网络链" center
                    v-model="userObj.chainName" label="交易网络链:" />
                <!-- USDT钱包地址 -->
                <van-field name="chainAddress" label-width="10em" border placeholder="请输入USDT钱包地址" center
                    v-model="userObj.chainAddress" label="USDT钱包地址:" />
                <!-- 钱包二维码上传 -->
                <van-field name="chainPic" label-width="10em" label="钱包二维码:" input-align="right" center>
                    <template #input>
                        <van-uploader :after-read="afterReadChain" :before-delete="deleteChain" max-count="1"
                            v-model="chainPicFile" />
                    </template>
                </van-field>
            </van-cell-group>
            <van-cell-group style="margin-top: 20px;" inset>
                <!-- 银行名称 -->
                <van-field name="bankName" label-width="10em" border placeholder="请输入银行名称" required center
                    v-model="userObj.bankName" label="银行名称:" :rules="[{ required: true }]" />
                <!-- 开户行名称 -->
                <van-field name="bankAddress" label-width="10em" border placeholder="请输入开户行名称" required center
                    v-model="userObj.bankAddress" label="开户行名称:" :rules="[{ required: true }]" />
                <!-- 银行卡号 -->
                <van-field name="bankCard" label-width="10em" border placeholder="请输入银行卡号" required center
                    v-model="userObj.bankCard" label="银行卡号:" :rules="[{ required: true }]" />
            </van-cell-group>
            <van-cell-group style="margin-top: 20px;" inset>
                <!-- 收款人姓名 -->
                <van-field name="realName" label-width="10em" border placeholder="请输入收款人姓名" required center
                    v-model="userObj.realName" label="收款人姓名:" :rules="[{ required: true }]" />
            </van-cell-group>
            <div class="vButton">
                <!-- 提交按钮 -->
                <van-button block color="rgb(166,84,138)" native-type="submit">提交保存</van-button>
            </div>
        </van-form>
        <div style="height: 40px;"></div>
    </div>
</template>

<script>
import { updateUser, getUserInfo } from '@/api/user';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';
import ImageCompressor from 'image-compressor.js'; // 导入图片压缩库

export default {
    name: 'changeUser',
    data() {
        return {
            wechatPicFile: [],
            alipayPicFile: [],
            chainPicFile: [],
            userObj: {},
            updateWechat: false,
            updateAlipay: false,
            updateChain: false,
        };
    },
    computed: {
        ...mapGetters(['userinfo']),
    },
    mounted() {
        // 获取用户信息并初始化表单
        this.userObj = this.userinfo;
        if (this.userObj.wechatPic) {
            this.wechatPicFile.push({ url: this.userObj.wechatPic, isImage: true });
        }
        if (this.userObj.alipayPic) {
            this.alipayPicFile.push({ url: this.userObj.alipayPic, isImage: true });
        }
        if (this.userObj.chainPic) {
            this.chainPicFile.push({ url: this.userObj.chainPic, isImage: true });
        }
    },
    methods: {
        // 删除微信收款码
        deleteWechat() {
            this.wechatPicFile.pop();
            this.updateWechat = true;
        },
        // 删除支付宝收款码
        deleteAlipay() {
            this.alipayPicFile.pop();
            this.updateAlipay = true;
        },
        // 删除钱包二维码
        deleteChain() {
            this.chainPicFile.pop();
            this.updateChain = true;
        },
        // 选择并压缩微信收款码后的回调
        async afterReadWechat(file) {
            try {
                const compressedFile = await new ImageCompressor().compress(file.file,{ quality: 0.3 });
                this.wechatPicFile = [{ ...file, file: compressedFile }];
                this.updateWechat = true;
            } catch (error) {
                console.error('微信收款码压缩失败:', error);
                Notify({ type: 'danger', message: '微信收款码压缩失败' });
            }
        },
        // 选择并压缩支付宝收款码后的回调
        async afterReadAlipay(file) {
            try {
                const compressedFile = await new ImageCompressor().compress(file.file,{ quality: 0.3 });
                this.alipayPicFile = [{ ...file, file: compressedFile }];
                this.updateAlipay = true;
            } catch (error) {
                console.error('支付宝收款码压缩失败:', error);
                Notify({ type: 'danger', message: '支付宝收款码压缩失败' });
            }
        },
        // 选择并压缩钱包二维码后的回调
        async afterReadChain(file) {
            try {
                const compressedFile = await new ImageCompressor().compress(file.file,{ quality: 0.3 });
                this.chainPicFile = [{ ...file, file: compressedFile }];
                this.updateChain = true;
            } catch (error) {
                console.error('钱包二维码压缩失败:', error);
                Notify({ type: 'danger', message: '钱包二维码压缩失败' });
            }
        },
        // 返回上一页
        onClickLeft() {
            this.$router.back();
        },
        // 提交表单
        async onSubmit() {
            const formData = new FormData();
            const { storeId, storeName, storeOwnerId, authorities, accountNonExpired, accountNonLocked, credentialsNonExpired,withOpen, ...userInfo } = this.userObj;
            formData.append('userJson', new Blob([JSON.stringify(userInfo)], { type: 'application/json' }));
            if (this.wechatPicFile.length > 0) {
                formData.append('wechatPic', this.wechatPicFile[0].file);
            }
            if (this.alipayPicFile.length > 0) {
                formData.append('alipayPic', this.alipayPicFile[0].file);
            }
            if (this.chainPicFile.length > 0) {
                formData.append('chainPic', this.chainPicFile[0].file);
            }
            formData.append('updateWechat', this.updateWechat);
            formData.append('updateAlipay', this.updateAlipay);
            formData.append('updateChain', this.updateChain);
            try {
                const response = await updateUser(formData);
                Notify({ type: response.data.success ? 'success' : 'danger', message: response.data.msg });
                this.userInfo();
                this.$router.push('/settings');
            } catch (error) {
                console.error('提交保存失败:', error);
                Notify({ type: 'danger', message: '提交保存失败' });
            }
        },
        // 获取用户信息
        async userInfo() {
            try {
                const response = await getUserInfo();
                this.$store.dispatch('ADD_USERINFO', response.data.data);
            } catch (error) {
                console.error('获取用户信息失败:', error);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.vButton {
    width: 90%;
    margin: 30px auto 0;
}
</style>