<template>
    <div>
        <van-sticky>
            <van-nav-bar title="邀请好友" left-arrow @click-left="onClickLeft" />
        </van-sticky>
        <div style="text-align: left;width: 90%;margin: 0 auto;">
            <h1>分红瓜分上不封顶</h1>
            <div>邀请好友，共同实现管道收益。</div>
            <div class="qrcode">
                <img :src="qrCode" alt="" srcset="" width="200" height="200">
            </div>
            <h2>专属邀请链接</h2>
            <div class="inviteBox">
                <div style="border-bottom: 1px solid black;">链接：https:240629.online/login?parentName={{
                    userinfo.inviteCode }}
                    <van-icon style="margin-left: 10px;" :name="copyIcon" size="20px"
                        @click="copyInviteCode('http://240629.online/login?parentName=' + userinfo.inviteCode)" />
                </div>
                <div>邀请码：<span>{{ userinfo.inviteCode }}
                        <van-icon style="margin-left: 10px;" :name="copyIcon" size="20px"
                            @click="copyInviteCode(userinfo.inviteCode)" />
                    </span> </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getQRcode } from '@/api/qrcode';
import { mapGetters } from 'vuex';
import { Notify } from 'vant';
export default {
    name: 'inviteFriend',
    data() {
        return {
            copyIcon: require("@/assets/copy.png"),
            qrCode:''
        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {
        this.getQr()
    },
    methods: {
        async getQr() {
            let data = {
                url: 'https://240629.online/login?parentName=' + this.userinfo.inviteCode
            }
            await getQRcode(data).then(res => {
                const blob = new Blob([res.data], { type: res.type });
                let src = window.URL.createObjectURL(blob);
                this.qrCode = src;
            })
        },
        onClickLeft() {
            this.$router.back()
        },
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
    }
};
</script>
<style lang='less' scoped>
.inviteBox {
    border: 2px solid black;
    padding: 20px;
    div {
        padding: 10px 0;
        font-size: 14px;
    }
}
.qrcode{
    margin: 20px 0;
    text-align: center;
}
</style>