<template>
    <div>
        <van-sticky style="background-color: rgb(165,35,136);">
            <van-nav-bar :border="false" title="记账统计" left-arrow @click-left="onClickLeft" />
        </van-sticky>
        <div class="content">
            <van-cell-group inset>
                <van-field :value="userinfo.storeName" readonly label="店铺名称" />
                <van-field value="全部" label="订单状态" readonly />
                <van-field :value="dateTime" label="统计日期" readonly>
                    <template #button>
                        <van-button size="small" type="primary" @click="downloadReport">下载报表</van-button>
                    </template>
                </van-field>
            </van-cell-group>
            <div class="integralList">
                <div class="cardList">
                    <div class="card">
                        <div class="title">总积分</div>
                        <div class="value">{{ allMoney }}</div>
                    </div>
                    <div class="card">
                        <div class="title">服务分</div>
                        <div class="value">{{ allListingFee }}</div>
                    </div>
                </div>
                <div class="cardList">
                    <div class="card">
                        <div class="title">分团总积分</div>
                        <div class="value">0</div>
                    </div>
                    <div class="card">
                        <div class="title">分团服务分</div>
                        <div class="value">0</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import { formatDateToChinese, getCurrentTime } from '@/utils/DateTime'
import { getOrdersByStore } from '@/api/orders';
import Decimal from 'decimal.js';

export default {
    name: 'tally',
    data() {
        return {
            dateTime: formatDateToChinese(new Date()),
            orderList: [],
            allMoney: 0,
            allListingFee: 0
        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {
        this.getOrderList()
    },
    methods: {
        getAllMoney() {
            if (this.orderList.length != 0) {
                this.allMoney = this.orderList.reduce((accumulator, currentValue) => { return new Decimal(accumulator).plus(currentValue.money) }, 0)
            }
        },
        getAllListingFee() {
            if (this.orderList.length != 0) {
                this.allListingFee = this.orderList.reduce((accumulator, currentValue) => { return new Decimal(accumulator).plus(currentValue.listingFee) }, 0)
            }
        },
        async getOrderList() {
            let data = { orderStatus: 3, storeCode: this.userinfo.storeCode, status: 1 }
            await getOrdersByStore(data).then(res => {
                this.orderList = res.data.data
                this.getAllListingFee()
                this.getAllMoney()
            })
        },
        onClickLeft() {
            this.$router.back()
        },
        downloadReport() {
            // 生成示例数据
            const data = [];
            this.orderList.forEach(item => {
                let order = [item.orderName,
                this.userinfo.storeName,
                item.seller.realName,
                item.seller.username,
                item.buyer.realName,
                item.buyer.username,
                item.money,
                item.listingFee];
                data.push(order);
            });
            const header = [
                [this.userinfo.storeName + "记账统计" + formatDateToChinese(new Date())],
                ["生成时间:" + getCurrentTime(new Date())],
                ["商品名", "团名称", "出售人", "出售人手机号", "购买人", "购买人手机号", "订单金额", "上架费"],
                ...data,
                ["", "团名称：" + this.userinfo.storeName, "总积分：" + this.allMoney, "总上架费：" + this.allListingFee, "",
                    "分团总积分：0", "分团服务分：0"]
            ];


            const workbook = XLSX.utils.book_new();
            const sheet = XLSX.utils.aoa_to_sheet(header);  // 使用 aoa_to_sheet 而不是 json_to_sheet
            const cellStyle = {
                font: { name: '宋体', sz: 12 }, // 设置字体和字号
                alignment: { horizontal: 'center', vertical: 'center', wrapText: true }
            };
            for (let cellAddress in sheet) {
                if (cellAddress[0] === '!') continue; // 跳过特殊标记行
                sheet[cellAddress].s = cellStyle; // 应用单元格样式
            }

            // 计算并设置每列宽度
            sheet['!cols'] = [];
            XLSX.utils.sheet_to_json(sheet, { header: 1 }).slice(2).forEach((row, rowIndex) => {
                row.forEach((cell, colIndex) => {
                    const cellWidth = (cell.toString().length + 2) * 2; // 根据实际需要调整系数
                    if (!sheet['!cols'][colIndex] || cellWidth > sheet['!cols'][colIndex].width) {
                        sheet['!cols'][colIndex] = { width: cellWidth };
                    }
                });
            });

            // 计算并设置每行高度
            sheet['!rows'] = [];
            header.forEach((rowData, rowIndex) => {
                let maxHeight = 0;
                rowData.forEach((cell, colIndex) => {
                    // 计算单元格高度，可以根据实际需要设置高度计算的逻辑
                    const cellHeight = cell.toString().length * 1.5; // 根据内容长度动态计算高度
                    if (!sheet['!rows'][rowIndex]) {
                        sheet['!rows'][rowIndex] = {};
                    }
                    sheet['!rows'][rowIndex].h = cellHeight; // 设置行高度
                });
            });

            // 添加到工作簿


            sheet["!merges"] = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }
            ];
            // 合并第二行第一列到第二行第八列的单元格范围
            sheet["!merges"].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } });
            XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
            // 将工作簿保存为Excel文件
            XLSX.writeFile(workbook, formatDateToChinese(new Date()) + this.userinfo.storeName + '报表.xlsx',{ type: 'arraybuffer' });
        }
    }
};
</script>
<style lang='less' scoped>
.content {
    background-color: rgb(165, 35, 136);
    padding: 20px 0px;
}

/deep/ [class*='van-hairline']::after {
    border: none;
}

.integralList {
    display: flex;
    flex-direction: column;
}

.cardList {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}

.card {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 40%;
    text-align: left;
}

.title {
    font-size: 16px;
    font-weight: 600;
}

.value {
    font-size: 16px;
    margin-top: 10px;
    color: red;
}
</style>
