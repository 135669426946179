export function formatDateToChinese(date) {
    const year = date.getFullYear(); // 获取年份
    const month = date.getMonth() + 1; // 获取月份，注意月份从 0 开始，所以要加 1
    const day = date.getDate(); // 获取日期

    // 使用模板字符串拼接成需要的格式
    const formattedDate = `${year}年${month}月${day}日`;
    return formattedDate;
}
export function getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1，并且保证两位数格式
    const day = String(now.getDate()).padStart(2, '0'); // 日期保证两位数格式
    const hours = String(now.getHours()).padStart(2, '0'); // 小时保证两位数格式
    const minutes = String(now.getMinutes()).padStart(2, '0'); // 分钟保证两位数格式
    const seconds = String(now.getSeconds()).padStart(2, '0'); // 秒钟保证两位数格式
  
    const currentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return currentTime;

}
export function isInTimeRange(startTime, endTime) {
    // 获取当前时间的小时和分钟
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    // 将开始时间和结束时间转换为小时和分钟
    const [startHour, startMinute] = startTime.split(':').map(num => parseInt(num, 10));
    const [endHour, endMinute] = endTime.split(':').map(num => parseInt(num, 10));

    // 构建开始时间和结束时间的 Date 对象
    const startDate = new Date();
    startDate.setHours(startHour, startMinute, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHour, endMinute, 0, 0);

    // 构建当前时间的 Date 对象
    const currentTime = new Date();
    currentTime.setHours(currentHour, currentMinute, 0, 0);

    // 比较当前时间是否在开始时间和结束时间之间
    return currentTime >= startDate && currentTime <= endDate;
}