<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  methods: {
    // 禁止缩放(浏览器中)
    forbidZoom() {
      document.addEventListener(
        'keydown',
        function (event) {
          if (
            (event.ctrlKey === true || event.metaKey === true) &&
            (event.keyCode === 61 ||
              event.keyCode === 107 ||
              event.keyCode === 173 ||
              event.keyCode === 109 ||
              event.keyCode === 187 ||
              event.keyCode === 189)
          ) {
            event.preventDefault();
          }
        },
        false
      );
 
      document.addEventListener(
        'mousewheel',
        function (e) {
          e = e || window.event;
          // @ts-ignore
          if ((e.wheelDelta && event.ctrlKey) || e.detail) {
            event.preventDefault();
          }
        },
        {
          capture: false,
          passive: false
        }
      );
    },
 
    // 禁止双指放大（手机端）
    forbidZoomClick() {
      document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      }, false);
    },
 
    // 禁止双击屏幕放大（手机端）
    forbidZoomDoubleClick() {
      var lastTouchEnd = 0;
 
      document.documentElement.addEventListener('touchend', function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false);
    }
  },
 
  mounted() {
    this.forbidZoom(); // dom挂载完成后，及禁止浏览器缩放
    this.forbidZoomClick();
    this.forbidZoomDoubleClick();
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  background: rgb(248,248,248);
}
.van-grid-item {
  font-size: 24px; /* 根据需要调整字体大小 */
}
</style>
