<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" title="提现审核" left-arrow @click-left="onClickLeft" fixed placeholder safe-area-inset-top />
        </van-sticky>
        <van-tabs v-model="active" ref="tabs" :before-change="beforeChange">
            <van-tab v-for="item, index in withDrawList" :title="item.title" :key="item.id">
                <van-empty description="暂无数据" v-if="item.data.length == 0 && index == active" />
                <div class="withdrawCard" v-for="withDraw in item.data">
                    <div class="title">
                        <span>流水号：{{ withDraw.withDrawId }}</span>
                        <span style="color: lightskyblue;font-size: 20px">{{
                            withDrawList.find(w => w.status ==withDraw.status).title }}</span>
                    </div>
                    <van-divider :style="{ color: 'black', borderColor: 'black', height: '2px' }" />
                    <div>提现金额<span style="color: red;">{{ withDraw.withDrawPrice }}</span></div>
                    <div>提现方式：{{ withDraw.withDrawWay }}</div>
                    <div>提现人：{{ withDraw.user.realName }}</div>
                    <div>手机号：{{ withDraw.user.username }}</div>
                    <div>邀请码：{{ withDraw.user.inviteCode }} <van-icon style="margin-left: 10px;" :name="copyIcon" size="20px"
                        @click="copyInviteCode(withDraw.user.inviteCode)" /></div>
                    <div>店铺名：{{ withDraw.storeName }}</div>
                    <div>申请时间：{{ withDraw.createTime }}</div>
                    <div>操作时间：{{ withDraw.updateTime }}</div>
                    <div class="buttonList" >
                        <van-button style="margin-left: 5px;" color="rgb(165,35,136)" plain
                            @click="showUserInfo(withDraw)">收款信息</van-button>
                        <van-button v-if="withDraw.status==0" color="rgb(165,35,136)" plain @click="comfirmW(withDraw)">确认审核并付款</van-button>
                        <van-button v-if="withDraw.status==0" color="rgb(165,35,136)" plain @click="cancelW(withDraw)">驳回审核</van-button>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <van-dialog v-model="isComfirm" title="确认审核" show-cancel-button @confirm="comfirmWithDraw">
            <div style="margin: 20px 0;">确认通过该提现审核吗</div>
        </van-dialog>
        <van-dialog v-model="isCancel" title="驳回审核" show-cancel-button @confirm="cancelWithDraw">
            <div style="margin: 20px 0;">确认驳回该审核吗</div>
        </van-dialog>
        <van-action-sheet v-model="show" title="收款信息">
            <van-cell-group inset>
                <van-field  v-if="withDraw.withDrawWay=='微信'" name="wechatPic" label="微信收款码:"
                    label-width="10em" center input-align="right">
                    <template #input>
                        <van-image width="6rem" height="6rem" :src="withDraw.user.wechatPic" />
                    </template>
                </van-field>
                <van-field v-if="withDraw.withDrawWay=='支付宝'" name="alipayAccount"
                    label-width="10em" border placeholder="请输入支付宝账号" center readonly v-model="withDraw.user.alipayAccount"
                    label="支付宝账号:">
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(withDraw.user.alipayAccount)" />
                    </template>
                </van-field>
                <van-field v-if="withDraw.withDrawWay=='支付宝'" name="alipayPic" label-width="10em"
                    label="支付宝收款码:" input-align="right" center>
                    <template #input>
                        <van-image width="6rem" height="6rem" :src="withDraw.user.alipayPic" />
                    </template>
                </van-field>
                <van-field v-if="withDraw.withDrawWay=='USDT钱包'" name="chainName" label-width="10em"
                    border placeholder="请输入交易网络链" center v-model="withDraw.user.chainName" label="交易网络链:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(withDraw.user.chainName)" />
                    </template>
                </van-field>
                <van-field v-if="withDraw.withDrawWay=='USDT钱包'" name="chainAddress"
                    label-width="10em" border placeholder="请输入USDT钱包地址" center v-model="withDraw.user.chainAddress"
                    label="USDT钱包地址:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(withDraw.user.chainAddress)" />
                    </template>
                </van-field>
                <van-field v-if="withDraw.withDrawWay=='USDT钱包'" name="chainPic" label-width="10em"
                    label="钱包二维码:" input-align="right" center>
                    <template #input>
                        <van-image width="6rem" height="6rem" :src="withDraw.user.chainPic" />
                    </template>
                </van-field>
                <van-field v-if="withDraw.withDrawWay=='银行卡'" name="bankName" label-width="10em" border
                    placeholder="请输入银行名称" center v-model="withDraw.user.bankName" label="银行名称:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(withDraw.user.bankName)" />
                    </template>
                </van-field>
                <van-field v-if="withDraw.withDrawWay=='银行卡'" name="bankAddress"
                    label-width="10em" border placeholder="请输入开户行名称" center v-model="withDraw.user.bankAddress"
                    label="开户行名称:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(withDraw.user.bankAddress)" />
                    </template>
                </van-field>
                <van-field v-if="withDraw.withDrawWay=='银行卡'" name="bankCard" label-width="10em" border
                    placeholder="请输入银行卡号" center v-model="withDraw.user.bankCard" label="银行卡号:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(withDraw.user.bankCard)" />
                    </template>
                </van-field>
                <div style="height: 30px;"></div>
            </van-cell-group>
        </van-action-sheet>
    </div>
</template>
<script>
import { changeWithDraw, userWithDraw, withDrawByStore } from '@/api/withDraw';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';

export default {
    name: 'userWithDraw',
    data() {
        return {
            active: 0,
            withDrawList: [
                {
                    id: 1,
                    status: 0,
                    title: '待审核',
                    data: []
                }, {
                    id: 2,
                    status: 1,
                    title: '审核完成',
                    data: []
                },
            ],
            show:false,
            withDraw:{},
            copyIcon: require("@/assets/copy.png"),
            isCancel:false,
            isComfirm:false
        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {
        this.findUserWithDraw()
    },
    methods: {
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
        comfirmW(data){
            this.withDraw = data
            this.isComfirm = true
        },
        cancelW(data){
            this.withDraw = data
            this.isCancel = true
        },
        async comfirmWithDraw(){
            let data = {...this.withDraw}
            data.status=1
            await changeWithDraw(data).then(res=>{
                Notify({type:res.data.success?'success':'danger',message:res.data.msg})
            })
            this.findUserWithDraw()
        },
        async cancelWithDraw(){
            let data = {...this.withDraw}
            data.status=2
            await changeWithDraw(data).then(res=>{
                Notify({type:res.data.success?'success':'danger',message:res.data.msg})
            })
            this.findUserWithDraw()
        },
        showUserInfo(withDraw){
            this.withDraw = withDraw
            this.show = true
        },
        async findUserWithDraw() {
            let data = {
                storeCode: this.userinfo.storeCode,
                status: this.withDrawList[this.active].status
            }
            await withDrawByStore(data).then(res => {
                this.withDrawList[this.active].data = res.data.data
            })
        },
        beforeChange(index) {
            this.active = index
            this.findUserWithDraw()
        },
        onClickLeft() {
            this.$router.back()
        },
    }
};
</script>
<style lang='less' scoped>
/deep/ [class*='van-hairline']::after {
    border: none;
}

/deep/ .van-tabs__wrap {
    position: sticky;
    top: 46px;
    z-index: 3;
}

.withdrawCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 85%;
    padding: 20px;
    margin: 20px auto 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    div:not(.title, .buttonList *) {
        margin-top: 10px;
        text-align: left;
    }
}

.title {
    display: flex;
    justify-content: space-between;
}

.buttonList {
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0 0 0;
}
</style>