import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:'',
    userInfo:{}
  },
  getters: {
    userinfo: state=>state.userInfo
  },
  mutations: {
    ADD_TOKEN(state,data){
      state.token = data
    },
    ADD_USERINFO(state,data){
      state.userInfo = data
    },
    DELETE_TOKEN(state){
      state.token = ''
    },
    DELETE_USERINFO(state){
      state.userInfo = {}
    }
  },
  actions: {
    ADD_TOKEN({commit},data){
      commit('ADD_TOKEN',data)
    },
    ADD_USERINFO({commit},data){
      commit("ADD_USERINFO",data)
    },
    DELETE_TOKEN({commit}){
      commit('DELETE_TOKEN')
    },
    DELETE_USERINFO({commit}){
      commit('DELETE_USERINFO')
    }
  },
  modules: {
  },
  plugins:[
    createPersistedState({
        storage:window.sessionStorage,
        reducer(v){
            return {
                userInfo:v.userInfo,
                token:v.token
            }
        }
    })
]
})
