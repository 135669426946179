<template>
  <div>
    <van-nav-bar title="我的设置" left-arrow @click-left="onClickLeft" />
    <div class="userCard">
      <img src="@/assets/default.png" alt="" width="80px" height="80px">
      <div class="right">
        <div class="userInfo">
          <div class="uname"> {{userinfo.realName }} </div>
          <div class="role">{{ roleList[userinfo.role] }}</div>
        </div>
        <div class="inviteCode">邀请码:{{ userinfo.inviteCode }}
          <van-icon style="margin-left: 10px;" :name="copyIcon" size="30px"
            @click="copyInviteCode(userinfo.inviteCode)" />
        </div>
      </div>
    </div>
    <div class="infoChange">
      <van-cell-group inset>
      <van-cell icon="info-o" title="信息绑定" is-link to="/changeUser" />
      <van-cell :icon="passwordIcon" title="修改密码" is-link to="/changePassword" />
      </van-cell-group>
    </div>
    <div class="layout">
      <van-button round  type="primary" color="rgb(166,84,138)" block @click="layOut">退出登录</van-button>
    </div>
  </div>
</template>
<script>
import { layout } from '@/api/login';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';

export default {
  name: 'settings',
  data() {
    return {
      roleList: {
        "admin": "管理员",
        "user": "会员",
        "leader": "团长"
      },
      copyIcon: require("@/assets/copy.png"),
      passwordIcon:require("@/assets/passwordIcon.png")
    }
  },
  computed: {
    ...mapGetters(["userinfo"])
  },
  mounted() {

  },
  methods: {
    onClickLeft() {
      this.$router.push("/home/user")
    },
    async copyInviteCode(code) {
      try {
        await navigator.clipboard.writeText(code);
        Notify({ type: 'success', message: "复制成功" });
      } catch (err) {
        Notify({ type: 'danger', message: '复制失败' });
      }
    },
    async layOut(){
      await layout().then(res=>{
        this.$store.dispatch('DELETE_TOKEN'),
        this.$store.dispatch("DELETE_USERINFO")
        Notify({ type: 'success', message: res.data.msg });
        this.$router.replace('/')
      })
    }
  }
};
</script>
<style lang='less' scoped>
.layout{
  width: 95%;
  margin: 20vh auto 0;
}
/deep/ .van-button--normal{
  font-size: 24px;
  font-weight: 600;
}
/deep/ .van-cell__title {
  text-align: left;
}
/deep/ .van-cell-group--inset{
  margin: 0 10px;
}
.infoChange{
  margin-top: 20px;
}
.userCard {
  background-color: rgb(220, 234, 234);
  height: 100px;
  width: 85%;
  margin: 20px auto 0px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .right {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 20px;

    .userInfo {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .uname {
        font-size: 28px;
        font-weight: 700;
      }

      .role {
        padding: 10px;
        margin-left: 10px;
        border-radius: 10px;
        background-color: rgb(166, 84, 138);
        color: #fff;
      }
    }

    .inviteCode {
      display: flex;
      align-items: center;
      margin-top: 20px
    }
  }
}
</style>