// permission.js
import Vue from 'vue';

import store from '@/store';

Vue.directive('permission', {
  inserted(el, binding) {
    const { value } = binding;
    const permissions = store.state.userInfo.role

    if (value && value instanceof Array && value.length > 0) {
      const hasPermission = value.some(permission => permissions.includes(permission));

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`need permissions!`);
    }
  }
});
