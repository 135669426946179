<template>
    <div>
        <van-sticky style="background-color: rgb(165,35,136);">
            <van-nav-bar :border="false" title="奖金明细" left-arrow @click-left="onClickLeft" />
            <div class="allPrice">累计收入：{{ $route.query.promotionMoney }}</div>
        </van-sticky>
        <van-tabs v-model="active" :before-change="beforeChange">
            <van-tab v-for="item, index in statusList" :key="item.id" :title="item.title">
                <van-empty description="暂无数据" v-if="item.data.length == 0 && index == active" />
                <div class="bonusCard" v-for="c in item.data">
                    <van-cell center :title-style="{fontSize:'18px',fontWeight:'600',textAlign:'left'}" :title="'直推收益（'+c.user.realName+'）'"
                     size="large" :label="c.createTime" >
                     <template #default>
                        <span style="font-size: 18px;font-weight: 600;color:#000">{{ c.bonusPrice }}</span><br>
                        <span>{{ c.user.profit }}</span>
                     </template>
                     </van-cell>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import { getByDate } from '@/api/bonus';
import { mapGetters } from 'vuex';

export default {
    name: 'bonus',
    props: {

    },
    data() {
        return {
            active:0,
            statusList:[
                {
                    id:1,
                    title:'本日',
                    data:[]
                },{
                    id:2,
                    title:"本周",
                    data:[]
                },{
                    id:3,
                    title:'本月',
                    data:[]
                }
            ]
        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {
        this.getBonusData()
    },
    methods: {
        beforeChange(index){
            this.active = index
            this.getBonusData()
        },
        async getBonusData(){
            await getByDate({date:this.statusList[this.active].id,uid:this.userinfo.uid}).then(res=>{
                this.statusList[this.active].data = res.data.data
            })
        },
        onClickLeft() {
            this.$router.back()
        },
    }
};
</script>
<style lang='less' scoped>
.allPrice{
    text-align: left;
    color: #fff;
    padding: 10px 60px;
}
</style>