<template>
    <div>
        <van-sticky style="background-color: rgb(165,35,136);">
            <van-nav-bar :border="false" title="业绩订单" left-arrow @click-left="onClickLeft" />
        </van-sticky>
        <van-tabs v-model="active" :before-change="beforeChange">
            <van-tab v-for="item, index in statusList" :key="item.orderStatus" :title="item.title"
                :badge="item.data.length != 0 && item.data != null ? item.data.length : ''">
                <div class="num">
                    订单记账总额：{{ allMoney }}，订单总数：{{ item.data.length != 0 && item.data != null ? item.data.length : 0 }}
                </div>
                <van-empty description="暂无数据" v-if="item.data.length == 0 && index == active" />
                <div class="orderList">
                    <div class="shopCard" v-for="shop in item.data">
                        <div class="cardId">
                            <span style="text-align:left;font-size: 16px">编号：{{ shop.orderId }}</span>
                            <span style="float: right;font-size: 14px;color: rgb(165,35,136);font-weight: 600;">{{
                                statusList.find(d => d.orderStatus === shop.orderStatus).name }}</span>
                        </div>
                        <div class="info">
                            <div class="infoLeft">
                                <img src="@/assets/card1.png" alt="" width="180px" height="140px">
                                <div style="margin-top: 20px;font-size: 14px;">下单：{{ shop.createTime }}</div>
                                <div style="margin-top: 10px;font-size: 14px;">更新：{{ shop.updateTime }}</div>
                                <div class="profit">利润：{{ shop.profit }}</div>
                                <div class="profit">上架费：{{ shop.listingFee }}</div>
                            </div>
                            <div class="infoCo">
                                <div style="font-weight: 800;">{{ shop.orderName }}</div>
                                <div style="color: rgb(222,123,61);">价格：{{ shop.money }}</div>
                                <div>{{ shop.seller.realName }} <div class="seller">卖家</div>
                                </div>
                                <div>邀请码：{{ shop.seller.inviteCode }}
                                    <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                                        @click="copyInviteCode(shop.seller.inviteCode)" />
                                </div>
                                <div>{{ shop.buyer.realName }} <div class="buyer">买家</div>
                                </div>
                                <div>邀请码：{{ shop.buyer.inviteCode }}
                                    <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                                        @click="copyInviteCode(shop.buyer.inviteCode)" />
                                </div>
                                <div style="color:rgb(266,87,60);font-weight:700;text-align: right;">实付</div>
                                <div style="color:rgb(266,87,60);font-weight:700;text-align: right;">￥{{ shop.money }}
                                </div>
                                <div class="buttonList">
                                    <van-button  color="rgb(165,35,136)" plain
                                        @click="getSellInfo(shop)">收款信息</van-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <van-action-sheet v-model="showSellInfo" title="收款信息">
            <van-cell-group inset>
                <van-field v-if="sellInfo.wechatPic && sellInfo.wechatPic != ''" name="wechatPic" label="微信收款码:"
                    label-width="10em" center input-align="right">
                    <template #input>
                        <van-image width="6rem" height="6rem" :src="sellInfo.wechatPic" />
                    </template>
                </van-field>
                <van-field v-if="sellInfo.alipayAccount && sellInfo.alipayAccount != ''" name="alipayAccount"
                    label-width="10em" border placeholder="请输入支付宝账号" center readonly v-model="sellInfo.alipayAccount"
                    label="支付宝账号:">
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.alipayAccount)" />
                    </template>
                </van-field>
                <van-field v-if="sellInfo.alipayPic && sellInfo.alipayPic != ''" name="alipayPic" label-width="10em"
                    label="支付宝收款码:" input-align="right" center>
                    <template #input>
                        <van-image width="6rem" height="6rem" :src="sellInfo.alipayPic" />
                    </template>
                </van-field>
                <van-field v-if="sellInfo.chainName && sellInfo.chainName != ''" name="chainName" label-width="10em"
                    border placeholder="请输入交易网络链" center v-model="sellInfo.chainName" label="交易网络链:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.chainName)" />
                    </template>
                </van-field>
                <van-field v-if="sellInfo.chainAddress && sellInfo.chainAddress != ''" name="chainAddress"
                    label-width="10em" border placeholder="请输入USDT钱包地址" center v-model="sellInfo.chainAddress"
                    label="USDT钱包地址:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.chainAddress)" />
                    </template>
                </van-field>
                <van-field v-if="sellInfo.chainPic && sellInfo.chainPic != ''" name="chainPic" label-width="10em"
                    label="钱包二维码:" input-align="right" center>
                    <template #input>
                        <van-image width="6rem" height="6rem" :src="sellInfo.chainPic" />
                    </template>
                </van-field>
                <van-field v-if="sellInfo.bankName && sellInfo.bankName != ''" name="bankName" label-width="10em" border
                    placeholder="请输入银行名称" center v-model="sellInfo.bankName" label="银行名称:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankName)" />
                    </template>
                </van-field>
                <van-field v-if="sellInfo.bankAddress && sellInfo.bankAddress != ''" name="bankAddress"
                    label-width="10em" border placeholder="请输入开户行名称" center v-model="sellInfo.bankAddress"
                    label="开户行名称:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankAddress)" />
                    </template>
                </van-field>
                <van-field v-if="sellInfo.bankCard && sellInfo.bankCard != ''" name="bankCard" label-width="10em" border
                    placeholder="请输入银行卡号" center v-model="sellInfo.bankCard" label="银行卡号:" readonly>
                    <template #right-icon>
                        <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankCard)" />
                    </template>
                </van-field>
                <div style="height: 30px;"></div>
            </van-cell-group>
        </van-action-sheet>
        <div style="height: 30px;"></div>
    </div>
</template>
<script>
import { getOrdersByStore } from '@/api/orders';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';
import Decimal from 'decimal.js';
export default {
    name: 'storeOrder',
    data() {
        return {
            active: 0,
            statusList: [
                {
                    orderStatus: 4,
                    title: '全部',
                    data: []
                },
                {
                    orderStatus: 1,
                    title: '待付款',
                    name: '等待买家付款',
                    data: []
                },
                {
                    orderStatus: 2,
                    title: '待收款',
                    name: '等待卖家确认',
                    data: []
                },
                {
                    orderStatus: 3,
                    title: '已完成',
                    name: '交易完成',
                    data: [],
                }
            ],
            copyIcon: require("@/assets/copy.png"),
            sellInfo: {},
            showSellInfo: false,
            allMoney: 0,
        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {
        this.getFourList()
    },
    methods: {
        getAllMoney() {
            this.allMoney = this.statusList[this.active].data.reduce((accumulator, currentValue) => { return new Decimal(accumulator).plus(currentValue.money) }, 0)
        },
        getSellInfo(shop) {
            this.sellInfo = shop.seller
            this.showSellInfo = true
        },
        getFourList() {
            for (let i = 0; i < this.statusList.length; i++) {
                this.getOrderList({ orderStatus: this.statusList[i].orderStatus, storeCode: this.userinfo.storeCode, status: 1 }, i)
            }
        },
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
        beforeChange(index) {
            this.active = index
            let data = { orderStatus: this.statusList[index].orderStatus, storeCode: this.userinfo.storeCode, status: 1 }
            this.getOrderList(data, index)
            return new Promise((resolve) => {
                resolve(true);
            })
        },
        onClickLeft() {
            this.$router.back()
        },
        async getOrderList(data, index) {
            await getOrdersByStore(data).then(res => {
                this.statusList[index].data = res.data.data != null ? res.data.data : []
                this.getAllMoney()
            })
        }
    }
};
</script>
<style lang='less' scoped>
/deep/ .van-tabs__wrap {
    position: sticky;
    top: 46px;
    z-index: 3;
}

.num {
    position: sticky;
    top: 90px;
    z-index: 3;
    padding: 15px 0;
    text-align: center;
    color: #fff;
    background-color: rgb(165,35,136);
}

.seller {
    background-color: rgb(217, 95, 45);
    color: #fff;
    padding: 5px;
    display: inline;
    border-radius: 8px;
    margin-left: 10px;
}

.buyer {
    background-color: rgb(150, 206, 240);
    color: #fff;
    padding: 5px;
    display: inline;
    border-radius: 8px;
    margin-left: 10px;
}

/deep/ [class*='van-hairline']::after {
    border: none;
}

.shopCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 90%;
    padding: 10px 10px;
    margin: 10px auto 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cardId {
    display: flex;
    justify-content: space-between;
}

.info {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 0;
    justify-content: space-between;
}

.infoCo {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
    justify-content: space-between;
}

.infoLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profit {
    background-color: bisque;
    color: rgb(254, 24, 24);
    padding: 8px;
    text-align: left;
    width: max-content;
    margin-top: 10px;
    border-radius: 6px;
}

.buttonList {
    display: flex;
    justify-content: right;
    margin-top: 5px;
}
</style>