import { Get, Post, Put } from "@/request/request";

export function addStore(data){
    return Post("/store/add",data)
}

export function getAllS(data){
    return Get("/store/getAll",data)
}

export function getStore(){
    return Get("/store/getStore")
}

export function getStoreName(){
    return Get("/store/getStoreName")
}
export function changeStoreWOpen(data){
    return Get("/store/changeStoreWithOpen",data)
}
