<template>
    <div>
        <div class="title">个人中心</div>
        <div class="uText">
            <div>总览</div>
            <img src="@/assets/settings.png" alt="" width="40px" height="40px" @click="goSettings">
        </div>
        <div class="imgCard">
            <img src="@/assets/bg-money.png" alt="" width="140px" height="140px" style="opacity: 0.7;">
        </div>
        <div class="upPrice">
            <div class="price">{{userinfo.listingFee?userinfo.listingFee:0}}</div>
            <div class="priceDes">我的上架费（CNY）</div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'user',
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {

    },
    methods: {
        goSettings(){
            this.$router.push("/settings")
        }
    }
};
</script>
<style lang='less' scoped>
.title {
    background-color: rgb(165, 35, 136);
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    color: white;
  font-weight: 600;
}

.uText {
    display: flex;
    justify-content: space-between;
    width: 95%;
    font-size: 26px;
    align-items: center;
    font-weight: 600;
    margin: 20px auto 0;
}

.imgCard {
    width: 85%;
    margin: 20px auto 0px;
    padding: 20px;
    border-radius: 25px;
    background-color: rgb(214,170,194);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.upPrice{
    width: 75%;
    margin: 20px auto 0px;
    padding: 40px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: left;
    .price{
        font-size: 32px;
        font-weight: 700;
        color: rgb(166,84,138);

    }
    .priceDes{
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
    }
}
</style>