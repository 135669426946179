<template>
  <div>
    <van-sticky>
      <div class="title">首页</div>
    </van-sticky>
    <div class="middle">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" width="100%">
        <van-swipe-item><img src="@/assets/card1.png" /></van-swipe-item>
        <van-swipe-item><img src="@/assets/card2.png" /></van-swipe-item>
      </van-swipe>
      <div style="margin-top: 10px;">
        <van-grid :border="false" icon-size="40px">
          <van-grid-item :icon="billing" text="待付记账" />
          <van-grid-item :icon="bookkeeping" text="待收记账" />
          <van-grid-item :icon="menu" text="行业介绍" />
          <van-grid-item :icon="people" text="关于我们" />
        </van-grid>
      </div>
      <div class="travelCard">
        <div class="travel">
          <img src="@/assets/store.png" width="70px" />
          <div>{{ userinfo.storeCode }}</div>
          <div>{{ userinfo.storeName }}</div>
        </div>
        <div class="rightBox">
          <router-link to="/userSolitaire">
            <div class="solitaire">
            <div>
              <img src="@/assets/solitaire.png" width="50px" height="50px" />
            </div>
            <div style="margin-left: 10px;font-size: 14px;font-weight: 600;color: #fff;">接龙</div>
          </div>
          </router-link>       
          <div class="content">
            <div>
              <img src="@/assets/log-content.png" width="50px" height="50px" />
            </div>
            <div style="font-size: 14px;font-weight: 600;color: #fff;">顶级精选内容</div>
          </div>
        </div>
      </div>
    </div>
    <div class="titleIn">旅游卡展示</div>
    <div class="travelShow">
      <div class="travelCardShow">
        <div class="cardContent">
          <div class="card">
            <img src="@/assets/card1.png" alt="" />
            <div>精品路线</div>
          </div>
          <div class="card">
            <img src="@/assets/card2.png" alt="" />
            <div>精品路线</div>
          </div>
          <div class="card">
            <img src="@/assets/card2.png" alt="" />
            <div>精品路线</div>
          </div>
          <div class="card">
            <img src="@/assets/card1.png" alt="" />
            <div>精品路线</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'index',
  data() {
    return {
      billing: require('@/assets/billing.png'),
      bookkeeping: require('@/assets/bookkeeping.png'),
      menu: require('@/assets/menu.png'),
      people: require('@/assets/people.png')
    }
  },
  computed: {
    ...mapGetters(["userinfo"])
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>
<style lang='less' scoped>

.title {
  background-color: rgb(165, 35, 136);
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: white;
  font-weight: 600;
}

.middle {
  background-color: white;
  width: 85%;
  margin: 20px auto 0px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.travelCard {
  display: flex;

  .travel {
    background-color: rgb(218, 168, 197);
    border-radius: 20px;
    width: 48%;
    height: 140px;
    padding: 10px;
    div{
      font-size: 16px;
      font-weight: 600;
      margin-top: 5px;
      color: aliceblue;
    }
  }

  .rightBox {
    width: 42%;
    margin-left: 15px;

    .solitaire {
      background-color: rgb(165, 35, 136);
      align-items: center;
      justify-content: center;
      display: flex;
      margin-bottom: 10px;
      border-radius: 20px;
      height: 70px;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      border-radius: 20px;
      background-color: rgb(13, 133, 188);
      height: 80px;
    }

  }
}



.titleIn {
  font-size: 24px;
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
}

.travelShow {
  width: 95%;
  margin-top: 30px;
  margin: 0 auto;

  .travelCardShow {
    .cardContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
      columns: 2;
      row-gap: 20px;

      .card {
        width: 43%;
        background-color: white;
        padding: 10px;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        img {
          width: 100%;
        }
      }
    }
  }

}

</style>