<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" :title="storeName + '成员'" left-arrow @click-left="onClickLeft" placeholder
                safe-area-inset-top />
        </van-sticky>
        <van-cell-group inset style="margin-top: 10px;">
            <van-field label-width="8em" size="large" v-model="todayPerson" readonly label="今日新增（人）"
                input-align="right" />
            <van-field label-width="8em" size="large" v-model="allPerson" readonly label="总人数（人）" input-align="right" />
        </van-cell-group>
        <div class="content">
            <div class="userCard" v-for="user in userList">
                <div class="realName">{{ user.realName }}
                    <div class="seller">{{ whoList[user.role] }}</div>
                </div>
                <div class="username">手机号：{{ user.username }}</div>
                <div>邀请码：{{ user.inviteCode }}<van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                        @click="copyInviteCode(user.inviteCode)" /></div>
                <div>直推人数：{{ user.childrenNum }}</div>
                <div class="createTime">注册时间：{{ user.createTime }}</div>
                <div v-if="user.parent">邀请人：{{ user.parent.realName }}</div>
                <div v-if="user.parent">邀请人手机号码：{{ user.parent.username }}</div>
                <div v-if="user.parent">邀请人邀请码：{{ user.parent.inviteCode }}<van-icon style="margin-left: 5px;"
                        :name="copyIcon" size="20px" @click="copyInviteCode(user.parent.inviteCode)" /></div>
            </div>
        </div>
    </div>
</template>
<script>
import { getUserNumByStore } from '@/api/user';
import { Notify } from 'vant';

export default {
    name: 'adminUser',
    data() {
        return {
            todayPerson: 0,
            allPerson: 0,
            userList: [],
            storeName: '',
            storeCode: "",
            whoList: {
                "admin": "管理员",
                "leader": "团长",
                "user": "用户"
            },
            copyIcon: require("@/assets/copy.png"),
        }
    },
    computed: {
    },
    watch: {

    },
    mounted() {
        this.storeCode = this.$route.query.storeCode
        this.storeName = this.$route.query.storeName
        this.getInviteNum()
    },
    methods: {
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
        onClickLeft() {
            this.$router.back()
        },
        async getInviteNum() {
            await getUserNumByStore({ storeCode: this.storeCode }).then(res => {
                this.todayPerson = res.data.data.todayPerson
                this.allPerson = res.data.data.allPerson
                this.userList = res.data.data.userList
            })
        },
    }
};
</script>
<style lang='less' scoped>
/deep/ .van-cell {
    background-color: rgb(165, 35, 136);
}

/deep/ .van-field__label {
    color: #fff;
    font-size: 18px;
}

/deep/ .van-field__control {
    color: #fff;
    font-size: 18px;
}

/deep/ .van-cell--large {
    padding: 18px 12px;
}

.content {
    margin-top: 20px;
}

.seller {
    background-color: rgb(217, 95, 45);
    color: #fff;
    padding: 10px 10px 0;
    display: inline;
    border-radius: 8px;
    margin-left: 10px;
}

.userCard {
    margin: 20px;
    border-bottom: 1px solid rgb(138, 137, 137);
    text-align: left;

    div {
        padding-bottom: 15px;
    }
}

.realName,
.username {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}
</style>