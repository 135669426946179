import { Post ,Get} from "@/request/request";

export function createSolitaire(data){
    return Post("/solitaire/create",data)
}

export function finishToday(data){
    return Get("/solitaire/finishToday",data)
}
export function getUserNext(data){
    return Get("/solitaire/getUserNext",data)
}
export function getByStore(data){
    return Get("/solitaire/getByStore",data)
}

