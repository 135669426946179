<template>
    <div>
        <van-sticky>
            <van-nav-bar title="我的粉丝" left-arrow @click-left="onClickLeft" fixed placeholder safe-area-inset-top />
            <van-cell-group inset style="margin-top: 10px;">
                <van-field label-width="8em" size="large" v-model="todayInvite" readonly label="今日新增（人）"
                    input-align="right" />
                <van-field label-width="8em" size="large" v-model="allInvite" readonly label="直推人数（人）"
                    input-align="right" />
            </van-cell-group>
        </van-sticky>
        <div class="content">
            <div class="userCard" v-for="user in userList">
                <div class="realName">{{ user.realName }}</div>
                <div class="username">手机号：{{ user.username }}</div>
                <div>邀请码：{{ user.inviteCode }}
                    <van-icon style="margin-left: 10px;" :name="copyIcon" size="20px"
                                    @click="copyInviteCode(user.inviteCode)" />
                </div>
                <div>直推人数：{{ user.childrenNum }}</div>
                <div class="createTime">注册时间：{{ user.createTime }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { getInviteNumObj } from '@/api/user';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';

export default {
    name: 'userFan',
    data() {
        return {
            todayInvite: 0,
            allInvite: 0,
            userList: [],
            copyIcon: require("@/assets/copy.png"),

        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {
        this.getInviteNum()
    },
    methods: {
        onClickLeft() {
            this.$router.back()
        },
        async getInviteNum() {
            await getInviteNumObj({ inviteCode: this.userinfo.inviteCode }).then(res => {
                this.todayInvite = res.data.data.todayInvite
                this.allInvite = res.data.data.allInvite
                this.userList = res.data.data.userList
            })
        },
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
    }
};
</script>
<style lang='less' scoped>
/deep/ .van-cell {
    background-color: rgb(165, 35, 136);
}

/deep/ .van-field__label {
    color: #fff;
    font-size: 18px;
}

/deep/ .van-field__control {
    color: #fff;
    font-size: 18px;
}

/deep/ .van-cell--large {
    padding: 18px 12px;
}

.content {
    margin-top: 20px;
}

.userCard {
    margin: 20px;
    border-bottom: 1px solid rgb(138, 137, 137);
    text-align: left;
    div{
        padding-bottom: 15px;
    }
}
.realName,.username{
    font-size: 18px;
    font-weight: 600;
    color: #000;
}
</style>