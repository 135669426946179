<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" title="旅游团管理" left-arrow @click-left="onClickLeft" />
            <div class="num">
                旅游团总数：{{ storeList.length }}
            </div>
            <div>
                <van-field v-model="storeName" center clearable placeholder="请输入旅游团名称">
                    <template #label>
                        <van-button type="info" @click="show = !show">添加旅游团</van-button>
                    </template>
                    <template #button>
                        <van-button round type="info" icon="search" @click="getAllStore"></van-button>
                    </template>
                </van-field>
            </div>
        </van-sticky>
        <div class="storeList">
            <div class="storeCard" v-for="item in storeList">
                <div class="top">
                    <img src="@/assets/dukan.png" alt="" srcset="" width="80px" height="80px">
                    <div class="cardText">
                        <div>商铺名：{{ item.storeName }}</div>
                        <div>商铺代码：{{ item.storeCode }}</div>
                        <div>团长：{{ item.user.realName }}</div>
                        <div>手机号：{{ item.user.username }}</div>
                    </div>
                </div>
                <div class="buttonList">
                    <van-button style="margin-left: 5px;" color="rgb(165,35,136)" plain
                        @click="toAdminTally(item.storeCode, item.storeName)">记账统计</van-button>
                    <van-button color="rgb(165,35,136)" plain
                        @click="toOrderList(item.storeCode, item.storeName, item.user.uid, item.user.realName)">订单信息</van-button>
                    <van-button style="margin-left: 5px;" color="rgb(165,35,136)" plain
                        @click="toStoreUser(item.storeCode, item.storeName)">会员情况</van-button>
                </div>
            </div>
        </div>
        <van-action-sheet v-model="show" title="添加旅游团">
            <van-form>
                <van-field v-model="storeInfo.storeName" name="storeName" label="店铺名" placeholder="店铺名"
                    :rules="[{ required: true, message: '请填写店铺名' }]" />
                <van-field :rules="[{ required: true, message: '请填写店铺名' }]" :value="storeInfo.storeCode"
                    name="storeCode" readonly label="店铺代码" placeholder="根据店铺号自动生成" />
                <van-field v-model="username" center clearable label="手机号" placeholder="请输入手机号">
                    <template #button>
                        <van-button size="small" type="primary" @click="findUser">查询</van-button>
                    </template>
                </van-field>
                <van-field label="指派给" :value="user ? user.realName : ''" readonly />
                <div style="margin: 16px;">
                    <van-button round block type="info" @click="addTravelGroup">提交</van-button>
                </div>
            </van-form>
        </van-action-sheet>
    </div>
</template>
<script>
import { addStore, getAllS } from '@/api/store';
import { getAllUserInfo, getUserByUserName } from '@/api/user';
import { Notify } from 'vant';
import pinyin from 'pinyin';
export default {
    name: 'travelGroup',
    data() {
        return {
            show: false,
            cardPng: require('@/assets/card1.png'),
            storeName: '',
            storeList: [],
            checked: true,
            storeInfo: {
                storeName: '',
                storeCode: '',
                storeOwnerId: null,
            },
            user: {},
            username: ''
        }
    },
    computed: {
    },
    watch: {
        'storeInfo.storeName': {
            handler: function (newInput, oldVal) {
                var pinyinFirstLetters = '';
                for (var i = 0; i < newInput.length; i++) {
                    var char = newInput.charAt(i);
                    var pinyinArray = pinyin(char, {
                        style: pinyin.STYLE_FIRST_LETTER
                    });
                    pinyinFirstLetters += pinyinArray[0][0].toLowerCase();  // 小写形式
                }
                if (newInput == '') {
                    this.$set(this.storeInfo, 'storeCode', pinyinFirstLetters);
                } else {
                    this.$set(this.storeInfo, 'storeCode', pinyinFirstLetters + '123');
                }
            },
            deep: true
        }
    },
    mounted() {
        this.getAllStore();
    },
    methods: {
        toAdminTally(storeCode, storeName) {
            this.$router.push({ path: '/adminTally', query: { storeCode, storeName } })
        },
        toOrderList(storeCode, storeName, uid, realName) {
            this.$router.push({ path: '/adminManageOrder', query: { storeCode, storeName, uid, realName } })
        },
        toStoreUser(storeCode, storeName) {
            this.$router.push({ path: '/adminUser', query: { storeCode, storeName } })
        },
        async getAllStore() {
            await getAllS({ storeName: this.storeName }).then(res => {
                this.storeList = res.data.data
            })
        },
        onClickLeft() {
            this.$router.back()
        },
        async addTravelGroup() {
            if (this.storeInfo.storeName == '' || this.storeInfo.storeOwnerId == null || this.username == '' || this.storeInfo.storeCode == '') {
                Notify({ type: 'warning', message: '请输入完整数据' })
            } else {
                await addStore(this.storeInfo).then(res => {
                    Notify({ 'type': res.data.success ? 'success' : 'danger', message: res.data.msg })
                    this.show = false
                })
                this.getAllStore()
            }
        },
        async findUser() {
            if (this.username == '') {
                Notify({ type: 'warning', message: '请输入手机号' })
            } else {
                await getUserByUserName({ username: this.username }).then(res => {
                    if (res.data.data) {
                        this.user = res.data.data;
                        this.storeInfo.storeOwnerId = this.user.uid
                        Notify({ type: res.data.success ? 'success' : 'danger', message: res.data.msg })
                    }
                })
            }
        }
    }
};
</script>
<style lang='less' scoped>
/deep/ .van-field__label {
    width: 7.4em;
}

.num {
    padding: 10px 0;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background-color: rgb(165, 35, 136);
}

.storeList {
    margin-top: 20px;
}

.top {
    display: flex;
    flex-direction: row;
}

.storeCard {
    display: flex;
    background-color: #fff;
    width: 85%;
    padding: 30px 20px;
    margin: 20px auto 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    flex-direction: column;
}

.cardText {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: 600;
    flex: 1;

    div:not(.buttonList *) {
        margin-top: 10px;
    }
}

.content {
    padding: 16px 16px 160px;
}

.buttonList {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}
</style>