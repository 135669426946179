<template>
    <div>
        <div class="loginContent" v-show="isLoginPage">
            <div class="header">
                <h1>登录</h1>
                <p>欢迎您再次使用</p>
            </div>
            <div class="loginForm">
                <van-form @submit="onSubmit" inset>
                    <van-field v-model="username" name="username" label="手机号" placeholder="请输入手机号" type="tel"
                        left-icon="user-o" :rules="[{ required: true, pattern, message: '请填写正确的手机号' }]" />
                    <van-field v-model="password" type="password" name="password" label="密码" placeholder="请输入密码"
                        :left-icon="passwordIcon" :rules="[{ required: true, message: '请填写密码' }]" />
                    <div style="margin: 30px;">
                        <van-button round block type="info" native-type="submit">登录</van-button>
                    </div>
                </van-form>
                <div class="textTik">
                    <div class="text">没有账号?<span @click="reverseLogin">立即注册</span></div>
                </div>
            </div>
        </div>
        <div class="registerContent" v-show="!isLoginPage">
            <div class="header">
                <h1>注册账号</h1>
                <p>欢迎您注册成为我们的用户</p>
            </div>
            <div class="loginForm">
                <van-form @submit="onRegister">
                    <van-field v-model="username" name="username" label="手机号" placeholder="请输入手机号" type="tel"
                        left-icon="user-o" :rules="[{ required: true, pattern, message: '请填写正确的手机号' }]" />
                    <van-field v-model="password" type="password" name="password" label="密码" placeholder="请输入密码"
                        :left-icon="passwordIcon" :rules="[{ required: true, message: '请填写密码' }]" />
                    <van-field v-model="parentName" type="text" name="parentName" label="邀请码" placeholder="请输入邀请码"
                        left-icon="share-o" :rules="[{ required: true, message: '请填写邀请码' }]" />
                    <div style="margin: 30px;">
                        <van-button round block type="info" native-type="submit">注册</van-button>
                    </div>
                </van-form>
                <div class="textTik">
                    <div class="text">已有账号?<span @click="reverseLogin">立即登录</span></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getUserInfo } from '@/api/user';
import { login, register } from '@/api/login';
import { Notify } from 'vant';
export default {
    name: 'login',
    data() {
        return {
            username: '',
            password: '',
            parentName: '',
            storeCode: '',
            passwordIcon: require('@/assets/passwordIcon.png'),
            pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
            isLoginPage: true
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        if (Object.keys(this.$route.query).includes("parentName")) {
            this.isLoginPage = false;
            this.parentName = this.$route.query.parentName
        }
    },
    methods: {
        reverseLogin() {
            this.isLoginPage = !this.isLoginPage;
            this.username = ''
            this.password = ''
            this.inviteCode = ''
            this.storeCode = ''
        },
        async userInfo() {
            await getUserInfo().then(res => {
                let userInfo = res.data.data
                this.$store.dispatch("ADD_USERINFO", userInfo)
                if(userInfo.realName==null||userInfo.realName==''){
                    this.$router.push("/changeUser")
                }else{
                    this.$router.push("/home/index")
                }
            })
        },
        async onSubmit(values) {
            await login(values).then(res => {
                if (res.data.success) {
                    this.$store.dispatch("ADD_TOKEN", res.data.data.tokenHead + ' ' + res.data.data.token)
                    this.userInfo()
                    Notify({ type: 'success', message: res.data.msg });
                } else {
                    Notify({ type: 'danger', message: res.data.msg });
                }
            })
        },
        async onRegister(values) {
            await register(values).then(res => {
                Notify({ type: res.data.success ? 'success' : 'danger', message: res.data.msg })
                this.reverseLogin()
            })
        }

    }
};
</script>
<style lang='less' scoped>
.header {
    width: 90%;
    text-align: left;
    margin: 120px auto 30px;

    h1 {
        margin: 10px 0;
    }
}

.loginForm {
    width: 90%;
    margin: 0 auto;
    font-size: 16px;
}

.textTik {
    span {
        color: blue;
        cursor: pointer;
    }
}
</style>