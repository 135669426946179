<template>
   <div>
    <van-sticky>
        <van-nav-bar title="团操作台" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <div class="userCard">
      <img src="@/assets/default.png" alt="" width="80px" height="80px">
      <div class="right">
        <div class="userInfo">
          <div class="uname"> {{userinfo.realName }} </div>
          <div class="role">{{ roleList[userinfo.role] }}</div>
        </div>
        <div class="inviteCode">邀请码:{{ userinfo.inviteCode }}
          <van-icon style="margin-left: 10px;" :name="copyIcon" size="30px"
            @click="copyInviteCode(userinfo.inviteCode)" />
        </div>
      </div>
    </div>
    <div class="controlTable">
      <van-cell-group inset>
        <van-cell  title="团员管理" is-link to="/storeUser" />
        <van-cell  title="订单管理" is-link to="/travelOrder" />
        <van-cell  title="并单列表" is-link  to="/mergeOrder" />
        <van-cell  title="商品管理" is-link  to="/travelCard" />
        <van-cell  title="上架审核" is-link  to="/shelveOrder" />
        <van-cell  title="提现审核" is-link to="/manageWithDraw" />
        <van-cell  title="记账统计" is-link to="/tally" />
        <van-cell  title="接龙管理" is-link to="/mSolitaire" /> 
      </van-cell-group>
    </div>
    <div class="bottom-padding"></div>
   </div>
</template> 
<script>
import { mapGetters } from 'vuex';
export default {
   name: 'storeManage',
   data() {
     return {
        roleList: {
        "admin": "管理员",
        "user": "会员",
        "leader": "团长"
      },
      copyIcon: require("@/assets/copy.png"),
     }
   },
   computed: {
    ...mapGetters(["userinfo"])
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
    onClickLeft() {
      this.$router.back()
    },
   }
};
</script>
<style lang='less' scoped>
.bottom-padding{
  height: 40px;
}
.userCard {
  background-color: rgb(220, 234, 234);
  height: 100px;
  width: 85%;
  margin: 20px auto 0px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .right {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 20px;

    .userInfo {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .uname {
        font-size: 28px;
        font-weight: 700;
      }

      .role {
        padding: 10px;
        margin-left: 10px;
        border-radius: 10px;
        background-color: rgb(166, 84, 138);
        color: #fff;
      }
    }

    .inviteCode {
      display: flex;
      align-items: center;
      margin-top: 20px
    }
  }
}
.controlTable{
  margin-top: 20px;
  text-align: left;
}
/deep/ .van-cell__left-icon{
  font-size: 24px;
  margin-right: 6px;
}
/deep/ .van-cell{
  font-size: 20px;
  font-weight: 500;
  padding: 15px 16px;
}
/deep/ .van-cell-group--inset{
  margin: 0 10px;
}
</style>