<template>
  <div>
    <van-sticky>
      <div class="title">记账本</div>
    </van-sticky>
    <div class="userCard">
      <div class="left"></div>
      <img src="@/assets/default.png" alt="" width="80px" height="80px">
      <div class="right">
        <div class="userInfo">
          <div class="uname"> {{ userinfo.realName }} </div>
          <div class="role">{{ roleList[userinfo.role] }}</div>
        </div>
        <div class="inviteCode">邀请码:{{ userinfo.inviteCode }}
          <van-icon style="margin-left: 10px;" :name="copyIcon" size="30px"
            @click="copyInviteCode(userinfo.inviteCode)" />
        </div>
      </div>
    </div>
    <div class="imgCard" v-permission="['leader', 'user']">
      <div class="divider"></div>
      <div class="moneyText">
        我的奖金
      </div>
      <van-button class="cashDrawal" color="rgb(149,78,116)" @click="withDrawShow">提现</van-button>
      <img src="@/assets/bg-money.png" alt="" width="120px" height="120px" style="opacity: 0.7;">
      <div class="canDrawal"><span class="number">{{ userinfo.profit || userinfo.profit == 0 ? userinfo.profit : '0.00'
          }}</span><br><span class="text">可提现奖金</span></div>
      <div class="finishDrawal"><span class="number">{{ withDrawnMoney != 0 ? withDrawnMoney : '0.00' }}</span><br><span
          class="text">已提现奖金</span></div>
    </div>
    <div class="cardList" v-permission="['leader', 'user']">
      <van-row gutter="10">
        <van-col span="12">
          <router-link to="/userWithDraw">
            <div class="card">
              <span>{{ withDrawNum }}笔</span>
              <div class="cardTitle">提现明细</div>
            </div>
          </router-link>
        </van-col>
        <van-col span="12">
          <router-link to="/userFan">
            <div class="card">
              <span>{{ userNum }}人</span>
              <div class="cardTitle">我的粉丝</div>
            </div>
          </router-link>
        </van-col>
      </van-row>
      <van-row gutter="10" style="margin-top: 20px;">
        <router-link :to="{ path: '/bonus', query: { promotionMoney } }">
          <van-col span="12">
            <div class="card">
              <span>{{ promotionMoney }}</span>
              <div class="cardTitle">推广金额</div>
            </div>
          </van-col>
        </router-link>
        <van-col span="12">
          <router-link to="/userChildOrder">
            <div class="card">
              <span>{{ orderNum }}笔</span>
              <div class="cardTitle">推广订单</div>
            </div>
          </router-link>
        </van-col>
      </van-row>
    </div>
    <div class="controlTable">
      <van-cell-group inset>
        <van-cell title="旅游团管理" v-permission="['admin']" is-link to="/travelGroup" />
        <van-cell :icon="performanceIcon" v-permission="['leader']" title="业绩统计" is-link to="/storeOrder" />
        <van-cell :icon="bonusmoneyIcon" v-permission="['leader', 'user']" title="奖金明细" is-link />
        <van-cell :icon="invitefriendIcon" v-permission="['leader', 'user']" title="邀请好友" is-link to="/inviteFriend" />
        <van-cell :icon="manageIcon" title="团管理" v-permission="['leader']" is-link to="/storeManage" />
      </van-cell-group>
    </div>
    <div style="height: 30px;"></div>
    <van-action-sheet v-model="withDraw" title="提现" @close="closeWithDraw">
      <div style="padding: 60px 0;">
        <van-form>
          <van-field v-model="withDrawPrice" type="number" center label="提现金额" placeholder="请输入要提现金额">
          </van-field>
          <van-field :value="userinfo.profit || userinfo.profit == 0 ? userinfo.profit : '0.00'" readonly center
            label="可提现金额">
          </van-field>
          <van-field readonly clickable name="picker" :value="value" label="提现方式" placeholder="点击选择提现方式"
            @click="showPicker = true" />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
          </van-popup>
          <div style="margin: 16px;">
            <van-button round block type="info" @click="confirmWithDraw">提交</van-button>
          </div>
        </van-form>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Notify } from 'vant';
import { createWithDraw, getLegerData, getUserWithDrawn } from '@/api/withDraw';
import { getUserInfo } from '@/api/user';
export default {
  name: 'ledger',
  data() {
    return {
      value: '',
      columns: ["微信", "支付宝", "USDT钱包", "银行卡"],
      showPicker: false,
      roleList: {
        "admin": "管理员",
        "user": "会员",
        "leader": "团长"
      },
      withDrawnMoney: 0,
      copyIcon: require("@/assets/copy.png"),
      bonusmoneyIcon: require('@/assets/bonusmoney.png'),
      invitefriendIcon: require('@/assets/invitefriend.png'),
      manageIcon: require("@/assets/manage.png"),
      performanceIcon: require("@/assets/performance.png"),
      cleanIcon: require("@/assets/clean.png"),
      withDraw: false,
      withDrawPrice: '',
      withDrawNum: 0,
      userNum: 0,
      promotionMoney: 0,
      orderNum: 0
    }
  },
  computed: {
    ...mapGetters(["userinfo"])
  },
  mounted() {
    this.getWithDrawn()
    this.findLedgerData()
  },
  methods: {
    async findLedgerData() {
      let data = {
        uid: this.userinfo.uid,
        inviteCode: this.userinfo.inviteCode
      }
      await getLegerData(data).then(res => {
          this.withDrawNum = res.data.data.withDrawNum
          this.userNum = res.data.data.userNum
          this.orderNum = res.data.data.orderNum
          this.promotionMoney = res.data.data.promotionMoney
      })
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    closeWithDraw() {
      this.withDraw = false
      this.withDrawPrice = ''
    },
    async confirmWithDraw() {
      if (Number(this.withDrawPrice) > this.userinfo.profit || this.withDrawPrice == '') {
        Notify({ type: 'danger', message: '请输入金额或小于可提现金额的数字' })
      } else {
        let data = {
          uid: this.userinfo.uid,
          status: 0,
          withDrawPrice: Number(this.withDrawPrice),
          storeCode: this.userinfo.storeCode,
          storeName: this.userinfo.storeName,
          withDrawWay: this.value
        }
        await createWithDraw(data).then(res => {
          Notify({ type: res.data.success ? 'success' : 'danger', message: res.data.msg })
          this.userInfo()
          this.withDraw = false
          this.withDrawPrice = ''
        })
      }
    },
    async userInfo() {
      await getUserInfo().then(res => {
        this.$store.dispatch("ADD_USERINFO", res.data.data)
      })
    },
    withDrawShow() {
      this.withDraw = true
    },
    async getWithDrawn() {
      await getUserWithDrawn({ uid: this.userinfo.uid, status: 1 }).then(res => {
        this.withDrawnMoney = res.data.data
      })
    },
    async copyInviteCode(code) {
      try {
        await navigator.clipboard.writeText(code);
        Notify({ type: 'success', message: "复制成功" });
      } catch (err) {
        Notify({ type: 'danger', message: '复制失败' });
      }
    },
  }
};
</script>
<style lang='less' scoped>
/deep/ .van-cell__left-icon {
  font-size: 24px;
  margin-right: 6px;
}

/deep/ .van-cell {
  font-size: 20px;
  font-weight: 500;
  padding: 15px 16px;
}

/deep/ .van-cell-group--inset {
  margin: 0 10px;
}

.title {
  background-color: rgb(165, 35, 136);
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: white;
  font-weight: 600;
}

.userCard {
  background-color: rgb(220, 234, 234);
  height: 100px;
  width: 85%;
  margin: 20px auto 0px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .right {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 20px;

    .userInfo {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .uname {
        font-size: 28px;
        font-weight: 700;
      }

      .role {
        padding: 10px;
        margin-left: 10px;
        border-radius: 10px;
        background-color: rgb(166, 84, 138);
        color: #fff;
      }
    }

    .inviteCode {
      display: flex;
      align-items: center;
      margin-top: 20px
    }
  }
}

.imgCard {
  position: relative;
  width: 85%;
  margin: 20px auto 0px;
  padding: 30px 20px;
  border-radius: 25px;
  background-color: rgb(214, 170, 194);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .divider {
    position: relative;
    width: 100%;
    border: 1px solid #fff;
    top: 60px;
    z-index: 3;
  }

  .moneyText {
    font-size: 26px;
    font-weight: 600;
    position: absolute;
  }

  .cashDrawal {
    position: absolute;
    right: 20px;
    font-size: 20px;
  }

  .canDrawal {
    position: absolute;
    line-height: 30px;
    bottom: 10px;
    left: 40px;
    color: #fff;

    .number {
      font-size: 32px;
      font-weight: 700;
    }

    .text {
      font-size: 20px;
    }
  }
}

.finishDrawal {
  position: absolute;
  line-height: 30px;
  bottom: 10px;
  right: 40px;
  color: #fff;

  .number {
    font-size: 32px;
    font-weight: 700;
  }

  .text {
    font-size: 20px;
  }
}

@cardColors: rgb(243, 223, 205), rgb(239, 215, 216), rgb(208, 228, 229), rgb(225, 249, 221);
@titlebColors: rgb(255, 233, 214), rgb(255, 221, 223), rgb(226, 253, 255), rgb(240, 255, 237);
@titleColors: rgb(234, 193, 143), rgb(235, 166, 167), rgb(165, 216, 214), rgb(188, 255, 176);

.cardList {
  width: 95%;
  margin: 20px auto;
}

@rows: 2;
@cols: 2;
@total: @rows * @cols;

// 循环生成样式
.generateStyles(@index) when (@index <=@total) {
  @row: ceil(@index / @cols);
  @col: mod((@index - 1), @cols) + 1;

  .cardList .van-row:nth-of-type(@{row}) .van-col:nth-of-type(@{col}) .card {
    height: 60px;
    padding: 10px;
    line-height: 60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;

    span {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      float: left;
    }

    background-color: extract(@cardColors, mod((@index - 1), length(@cardColors)) + 1);
  }

  .cardList .van-row:nth-of-type(@{row}) .van-col:nth-of-type(@{col}) .card .cardTitle {
    position: relative;
    top: 10px;
    float: right;
    color: #000;
    font-size: 14px;
    width: 70px;
    height: 30px;
    line-height: 30px;
    padding: 5px;
    background: linear-gradient(to right, extract(@titlebColors, mod((@index - 1), length(@titlebColors)) + 1), extract(@titleColors, mod((@index - 1), length(@titleColors)) + 1));
  }

  .generateStyles(@index + 1); // 递归调用
}

// 启动循环，从1到总数
.generateStyles(1);

.controlTable {
  text-align: left;
  margin-top: 20px;
}
</style>