<template>
    <div>
        <van-sticky>
            <van-nav-bar :border="false" title="提现管理" left-arrow @click-left="onClickLeft" fixed placeholder safe-area-inset-top />
        </van-sticky>
        <van-tabs v-model="active" ref="tabs" :before-change="beforeChange">
            <van-tab v-for="item, index in withDrawList" :title="item.title" :key="item.id">
                <van-empty description="暂无数据" v-if="item.data.length == 0 && index == active" />
                <div class="withdrawCard" v-for="withDraw in item.data">
                    <div class="title">
                        <span>流水号：{{ withDraw.withDrawId }}</span>
                        <span style="color: lightskyblue;font-size: 20px">{{ withDrawList.find(w=>w.status==withDraw.status).title }}</span>
                    </div>
                    <van-divider :style="{ color:'black',borderColor:'black',height:'2px'}" />
                    <div>提现金额<span style="color: red;">{{ withDraw.withDrawPrice }}</span></div>
                    <div>提现方式：{{withDraw.withDrawWay}}</div>
                    <div>店铺名：{{ withDraw.storeName }}</div>
                    <div>申请时间：{{ withDraw.createTime }}</div>
                    <div>操作时间：{{ withDraw.updateTime }}</div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import { userWithDraw } from '@/api/withDraw';
import { mapGetters } from 'vuex';

export default {
    name: 'userWithDraw',
    data() {
        return {
            active:0,
            withDrawList:[
                {
                    id:1,
                    status:3,
                    title:'全部',
                    data:[]
                },{
                    id:2,
                    status:0,
                    title:'待转账',
                    data:[]
                },{
                    id:3,
                    status:1,
                    title:'已打款',
                    data:[]
                },{
                    id:4,
                    status:2,
                    title:'失败',
                    data:[]
                }
            ]
        }
    },
    computed: {
        ...mapGetters(["userinfo"])
    },
    watch: {

    },
    mounted() {
        this.findUserWithDraw()
    },
    methods: {
        async findUserWithDraw(){
            let data = {
                uid:this.userinfo.uid,
                status:this.withDrawList[this.active].status
            }
            await userWithDraw(data).then(res=>{
                this.withDrawList[this.active].data = res.data.data
            })
        },
        beforeChange(index){
            this.active = index
            this.findUserWithDraw()
        },
        onClickLeft() {
            this.$router.back()
        },
    }
};
</script>
<style lang='less' scoped>
/deep/ [class*='van-hairline']::after {
    border: none;
}

/deep/ .van-tabs__wrap {
    position: sticky;
    top: 46px;
    z-index: 3;
}
.withdrawCard{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 85%;
    padding: 20px;
    margin: 20px auto 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    div:not(.title){
        margin-top: 10px;
        text-align: left;
    }
}
.title{
    display: flex;
    justify-content: space-between;
}
</style>