<template>
    <div>
        <van-nav-bar :border="false" title="上架审核" left-arrow @click-left="onClickLeft" fixed placeholder safe-area-inset-top />
        <div class="orderList">
            <van-empty description="暂无数据" v-if="this.shopList.length == 0" />
            <div class="shopCard" v-for="shop in shopList">
                <div class="cardId">
                    <span style="text-align:left;font-size: 16px">编号：{{ shop.orderId }}</span>
                    <span style="float: right;font-size: 14px;color: rgb(165,35,136);font-weight: 600;">交易完成</span>
                </div>
                <div class="info">
                    <div class="infoLeft">
                        <img src="@/assets/card1.png" alt="" width="180px" height="140px">
                        <div style="margin-top: 20px;font-size: 14px;">下单：{{ shop.createTime }}</div>
                        <div style="margin-top: 10px;font-size: 14px;">更新：{{ shop.updateTime }}</div>
                        <div class="profit">利润：{{ shop.profit }}</div>
                        <div class="profit">上架费：{{ shop.listingFee }}</div>
                    </div>
                    <div class="infoCo">
                        <div style="font-weight: 800;">{{ shop.orderName }}</div>
                        <div style="color: rgb(222,123,61);">价格：{{ shop.money }}</div>
                        <div>{{ shop.seller.realName }} <div class="seller">卖家</div>
                        </div>
                        <div>邀请码：{{ shop.seller.inviteCode }}
                            <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                                @click="copyInviteCode(shop.seller.inviteCode)" />
                        </div>
                        <div>{{ shop.buyer.realName }} <div class="buyer">买家</div>
                        </div>
                        <div>邀请码：{{ shop.buyer.inviteCode }}
                            <van-icon style="margin-left: 5px;" :name="copyIcon" size="20px"
                                @click="copyInviteCode(shop.buyer.inviteCode)" />
                        </div>
                        <div style="color:rgb(266,87,60);font-weight:700;text-align: right;">实付</div>
                        <div style="color:rgb(266,87,60);font-weight:700;text-align: right;">￥{{ shop.money }}
                        </div>
                        <div class="buttonList">
                            <van-button color="rgb(165,35,136)" plain @click="getSellInfo(shop)">收款信息</van-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="confirmShevle" @click="openShow">
                一键全上
            </div>
            <van-dialog v-model="show" title="一键上架" @confirm="confirmShevle" message="是否确认一键上架，一旦上架无法撤回"
                show-cancel-button>
            </van-dialog>
            <van-action-sheet v-model="showSellInfo" title="收款信息">
                <van-cell-group inset>
                    <van-field v-if="sellInfo.wechatPic && sellInfo.wechatPic != ''" name="wechatPic" label="微信收款码:"
                        label-width="10em" center input-align="right">
                        <template #input>
                            <van-image width="6rem" height="6rem" :src="sellInfo.wechatPic" />
                        </template>
                    </van-field>
                    <van-field v-if="sellInfo.alipayAccount && sellInfo.alipayAccount != ''" name="alipayAccount"
                        label-width="10em" border placeholder="请输入支付宝账号" center readonly
                        v-model="sellInfo.alipayAccount" label="支付宝账号:">
                        <template #right-icon>
                            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.alipayAccount)" />
                        </template>
                    </van-field>
                    <van-field v-if="sellInfo.alipayPic && sellInfo.alipayPic != ''" name="alipayPic" label-width="10em"
                        label="支付宝收款码:" input-align="right" center>
                        <template #input>
                            <van-image width="6rem" height="6rem" :src="sellInfo.alipayPic" />
                        </template>
                    </van-field>
                    <van-field v-if="sellInfo.chainName && sellInfo.chainName != ''" name="chainName" label-width="10em"
                        border placeholder="请输入交易网络链" center v-model="sellInfo.chainName" label="交易网络链:" readonly>
                        <template #right-icon>
                            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.chainName)" />
                        </template>
                    </van-field>
                    <van-field v-if="sellInfo.chainAddress && sellInfo.chainAddress != ''" name="chainAddress"
                        label-width="10em" border placeholder="请输入USDT钱包地址" center v-model="sellInfo.chainAddress"
                        label="USDT钱包地址:" readonly>
                        <template #right-icon>
                            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.chainAddress)" />
                        </template>
                    </van-field>
                    <van-field v-if="sellInfo.chainPic&& sellInfo.chainPic!= ''" name="chainPic"
                        label-width="10em" label="钱包二维码:" input-align="right" center>
                        <template #input>
                            <van-image width="6rem" height="6rem" :src="sellInfo.chainPic" />
                        </template>
                    </van-field>
                    <van-field v-if="sellInfo.bankName && sellInfo.bankName != ''" name="bankName" label-width="10em"
                        border placeholder="请输入银行名称" center v-model="sellInfo.bankName" label="银行名称:" readonly>
                        <template #right-icon>
                            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankName)" />
                        </template>
                    </van-field>
                    <van-field v-if="sellInfo.bankAddress && sellInfo.bankAddress != ''" name="bankAddress"
                        label-width="10em" border placeholder="请输入开户行名称" center v-model="sellInfo.bankAddress"
                        label="开户行名称:" readonly>
                        <template #right-icon>
                            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankAddress)" />
                        </template>
                    </van-field>
                    <van-field v-if="sellInfo.bankCard && sellInfo.bankCard != ''" name="bankCard" label-width="10em"
                        border placeholder="请输入银行卡号"  center v-model="sellInfo.bankCard" label="银行卡号:" readonly>
                        <template #right-icon>
                            <van-icon :name="copyIcon" size="20px" @click="copyInviteCode(sellInfo.bankCard)" />
                        </template>
                    </van-field>
                    <div style="height: 30px;"></div>
                </van-cell-group>
            </van-action-sheet>
            <div style="height: 30px;"></div>
        </div>
    </div>
</template>
<script>
import { getOrdersByStore, shelveOrder } from '@/api/orders';
import { Notify } from 'vant';
import { mapGetters } from 'vuex';
export default {
    name: 'shelveOrder',
    data() {
        return {
            copyIcon: require("@/assets/copy.png"),
            shopList: [],
            show: false,
            showSellInfo: false,
            sellInfo: {}
        }
    },
    computed: {
        ...mapGetters(['userinfo'])
    },
    mounted() {
        this.getShelveList()
    },
    methods: {
        onSearch(value) {
            if (value != '') {
                let orderList = this.shopList[this.active].data;
                let length = orderList.length;
                let list = []
                if (length != 0) {
                    for (let i = 0; i < length; i++) {
                        if (value == orderList[i].buyer.username || value == orderList[i].buyer.inviteCode
                            || value == orderList[i].seller.username || value == orderList[i].seller.inviteCode
                        ) {
                            list.push(orderList[i])
                        }
                    }
                }
                this.shopList[this.active].data = list
            } else {
                this.getCanMergeList()
            }
        },
        getSellInfo(shop) {
            this.sellInfo = shop.seller
            this.showSellInfo = true
        },
        async confirmShevle() {
            if (this.shopList.length == 0) {
                Notify({ type: 'danger', message: '暂无可上架的订单' })
            } else {
                await shelveOrder(this.shopList).then(res => {
                    Notify({ type: res.data.success ? 'success' : 'danger', message: res.data.msg })
                    this.getShelveList()
                })
            }
        },
        openShow() {
            this.show = true
        },
        async getShelveList() {
            let data = {
                orderStatus: 3,
                status: 1,
                storeCode: this.userinfo.storeCode
            }
            await getOrdersByStore(data).then(res => {
                this.shopList = res.data.data
            })
        },
        async copyInviteCode(code) {
            try {
                await navigator.clipboard.writeText(code);
                Notify({ type: 'success', message: "复制成功" });
            } catch (err) {
                Notify({ type: 'danger', message: '复制失败' });
            }
        },
        onClickLeft() {
            this.$router.back()
        },
    }
};
</script>
<style lang='less' scoped>
.seller {
    background-color: rgb(217, 95, 45);
    color: #fff;
    padding: 5px;
    display: inline;
    border-radius: 8px;
    margin-left: 10px;
}

.buyer {
    background-color: rgb(150, 206, 240);
    color: #fff;
    padding: 5px;
    display: inline;
    border-radius: 8px;
    margin-left: 10px;
}

/deep/ [class*='van-hairline']::after {
    border: none;
}

.shopCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 90%;
    padding: 10px 10px;
    margin: 10px auto 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cardId {
    display: flex;
    justify-content: space-between;
}

.info {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 0;
    justify-content: space-between;
}

.infoCo {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
    justify-content: space-between;
}

.buttonList {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}

.confirmShevle {
    position: fixed;
    bottom: 15vh;
    background-color: rgb(200, 179, 130);
    color: #fff;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    padding: 15px;
    left: 20px;
    z-index: 99;
}

.infoLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profit {
    background-color: bisque;
    color: rgb(254, 24, 24);
    padding: 8px;
    text-align: left;
    width: max-content;
    margin-top: 10px;
    border-radius: 6px;
}
</style>